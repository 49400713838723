<app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>

<div class="faq-container">
    <div class="faq-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="faq-welcome-card-content d-flex justify-content-between align-items-center align-self-stretch">
            <span>BulevART</span>
        </div>
    </div>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>