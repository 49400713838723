<div class="dialog">
    <div class="dialog__container">
        <div class="dialog__container-header d-flex justify-content-between align-items-center">
            <!-- <p>Consimțământ</p> -->
            <p><span class="title-content">NOTĂ DE INFORMARE </span> privind prelucrarea datelor cu caracter
                personal
                efectuată de către SECTORUL 4 al
                MUNICIPIULUI BUCUREȘTI prin
                intermediul facilităților digitale</p>
            <div>
                <button type="button" class="close d-flex align-items-center" aria-label="Close"
                    (click)="closeDialog()">
                    <span class="close-icon" aria-hidden="true">&times;</span>
                    <span class="close-text">&nbsp;&nbsp;ÎNCHIDE</span>
                </button>
            </div>
        </div>

        <!-- <p><span class="title-content">NOTĂ DE INFORMARE </span> <strong>privind prelucrarea datelor cu caracter
                personal
                efectuată de către SECTORUL 4 al
                MUNICIPIULUI BUCUREȘTI prin
                intermediul facilităților digitale</strong></p> -->

        <p class="text-content">Sectorul 4 al Municiului București, cu sediul în Bucureşti, Sector 4, Bulevardul George
            Coșbuc 6-16,
            București
            040532, reprezentată prin Domnul Daniel Băluță în calitate de Primar, Telefon: 021.335.92.30 /
            021.335.92.36,
            Fax: 021.337.07.90, E-mail: <a
                (click)="navigateToNewTab('mailto:contact@ps4.ro', false)">{{'contact@ps4.ro'}}</a>(denumită în cele ce
            urmează Primăria), vă informează cu privire
            la
            prelucrarea datelor personale și drepturile pe care le ai în conformitate cu REGULAMENTUL (UE) 2016/679 al
            Parlamentului European și al Consiliului din 27 aprilie 2016 (denumit în continuare GDPR) și legislația
            națională
            privind protecția și securitatea datelor personale, în vigoare.</p>

        <p class="text-content">
            Prezenta NOTĂ DE INFORMARE explică de ce prelucrăm, cum colectăm, procesăm și protejăm toate datele
            personale furnizate cu ocazia utilizării facilităților digitale, cum utilizăm informațiile și ce drepturi ai
            în raport cu
            datele tale cu caracter personal. De asemenea, conține datele de contact ale operatorului de date pe care îl
            poți
            contacta pentru a-ți exercita drepturile și ale Autorității Naționale de Supraveghere a Prelucrării Datelor
            cu
            Caracter Personal.
        </p>

        <div>
            <h3 class="title-content">
                1. Cine ești tu?
            </h3>
            <p class="text-content">
                Potrivit legislației, tu ești o „persoană vizată”, adică o persoană fizică identificată sau
                identificabilă,
                <strong>utilizator
                    al facilităților digitale implementate la nivelul Sectorului 4 al Municipiului București.</strong>
                Pentru a fi
                complet
                transparenți în privința prelucrării de date și pentru a-ți permite să îți exerciți cu ușurință, în
                orice
                moment,
                drepturile, am implementat măsuri pentru a facilita comunicarea dintre noi, operatorul de date și tu,
                persoana
                vizată.
            </p>
        </div>

        <div>
            <h3 class="title-content">
                2. Angajamentul nostru cu privire la protecția datelor personale
            </h3>
            <p class="text-content">
                Protecția informațiilor tale personale este foarte importantă pentru noi. De aceea, ne-am luat
                angajamentul să
                respectăm legislația europeană și națională privind protecția datelor cu caracter personal, în special
                Regulamentul
                (EU) 679/2016, cunoscut și sub denumirea de GDPR și următoarele principii:
            </p>
        </div>

        <div>
            <h3 class="title-content">
                3. Legalitate, echitate și transparență
            </h3>
            <p class="text-content">
                Prelucrăm datele tale legal și corect. Suntem întotdeauna transparenți în privința informațiilor pe care
                le
                utilizăm, iar tu ești informat corespunzător.
            </p>
        </div>

        <div>
            <h3 class="title-content">
                4. Controlul îți aparține
            </h3>
            <p class="text-content">
                În limitele legii, îți oferim posibilitatea de a vizualiza datele tale personale care fac obiectul
                prezentei și de a-ți
                exercita celelalte drepturi.
            </p>
        </div>

        <div>
            <h3 class="title-content">
                5. Integritatea datelor și limitarea scopului
            </h3>
            <p class="text-content">
                Utilizăm datele numai în scopurile descrise la momentul colectării sau în noi scopuri compatibile cu
                cele
                inițiale. În toate cazurile, scopurile noastre sunt compatibile cu legislația. Luăm măsuri rezonabile
                pentru a ne
                asigura că datele personale sunt corecte, complete și actualizate.
            </p>
        </div>

        <div>
            <h3 class="title-content">
                6. Securitate
            </h3>
            <p class="text-content">
                Am implementat măsuri rezonabile de securitate, criptare și acces restricționat, astfel încât să îți
                protejăm cât
                mai bine informațiile. Cu toate acestea, reține faptul că niciun site web, nicio aplicație și nicio
                conexiune la internet
                nu este complet sigură.
            </p>
        </div>

        <div>
            <h3 class="title-content">
                7. Schimbări
            </h3>
            <p class="text-content">
                Putem schimba Politica privind prelucrarea datelor cu caracter personal în orice moment, în funcție de
                circumstanțele legale sau alte circumstanțe care pot apărea în interesul tău sau al Primăriei.
            </p>
        </div>

        <div>
            <h3 class="title-content">
                8. Temeiul legal al prelucrării datelor
            </h3>
            <p class="text-content">
                <strong>Facilitățile digitale puse la dispoziția cetățenilor din Sectorul 4 al Municipiului
                    București</strong> a fost
                realizată în
                cadrul proiectului „Cetățenie activă într-o lume digitală”, cod SIPOCA/MySMIS: 1084/151411, proiect
                cofinanțat
                din Fondul Social European, prin Programul Operațional Capacitate Administrativă 2014-2020. Aceste
                facilități
                au fost implementate pentru asigurarea/realizarea/acordarea unor drepturi individuale stabilite prin
                legi cu ocazia
                utilizării platformelor digitalizate. Pentru a vă documenta asupra scopului, structurii și utilizării
                fiecăreia dintre
                facilitățile digitale, puteți accesa
                chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://hartaps4.go-
                ahead.ro/wp-content/uploads/2023/04/harta-facilitatilor-digitale-sector-4-Bucuresti.pdf
                Utilizarea acestor facilități implică prelucrarea anumitor categorii de date cu caracter personal,
                diferențiate în
                funcție de tipul serviciului public accesat de beneficiar. Acest tip de prelucrare de date se întemeiază
                pe
                dispozițiile articolului 6 alin. 1 lit. a din GDPR.
            </p>
            <p class="text-content">
                <strong>Legislația care guvernează, în principal, atribuțiile Sectorului 4 al Municiului
                    București</strong> în materia
                prevederilor art. 6 alin. 1 lit. a-e din GDPR este următoarea: Constituția României, OUG nr. 57/2019
                privind Codul
                Administrativ, cu modificările și completările ulterioare, Legea nr. 199/1997 pentru ratificarea Cartei
                europene a
                autonomiei locale, adoptată la Strasbourg la 15 octombrie 1985, LEGE nr. 544 din 12 octombrie 2001
                privind
                liberul acces la informațiile de interes public, O.G nr. 27/2002 privind reglementarea activității de
                soluționare a
                petițiilor, Legea 196/2018 privind înființarea, organizarea și funcționarea asociațiilor de proprietari
                și
                administrarea condominiilor, Legea nr.152/1998 și Legea locuinței nr.114/1996 și NORME METODOLOGICE
                din 26 februarie 2016 de aplicare a Legii nr. 350/2001 privind amenajarea teritoriului și urbanismul și
                de elaborare
                și actualizare a documentațiilor de urbanism, LEGEA Nr. 350/2001 privind amenajarea teritoriului si
                urbanismul
                cu modificãrile si completãrile ulterioare, LEGEA Nr. 50/1991 privind autorizarea executãrii lucrãrilor
                de
                constructii, LEGEA Nr. 52/2003 privind transparenta decizionalã în administratia publicã, LEGEA nr. 155
                din 12
                iulie 2010 (**republicată**) poliției locale, Legea asistenței sociale nr. 292/2011, cu modificările și
                completările
                ulterioare, Legea nr. 272/2004 privind protecția și promovarea drepturilor copilului, republicată, cu
                modificările și
                completările ulterioare si Comentariul general nr. 25 (2021) privind drepturile copiilor, Legea nr.
                448/2006
                privind protecția și promovarea drepturilor persoanelor cu handicap, republicată, cu modificările și
                completările
                ulterioare, și cu Legea nr. 221/2010 pentru ratificarea Convenției privind drepturile persoanelor cu
                dizabilități,
                adoptată la New York de Adunarea Generală a Organizației Națiunilor Unite la 13 decembrie 2006, deschisă
                spre
                semnare la 30 martie 2007 și semnată de România la 26 septembrie 2007, Ordonanța nr. 68/2003 privind
                serviciile
                sociale, Legea Nr.17/2000 privind asistența socială a persoanelor vârstnice, Republicată, Codul de
                procedură
                civilă, Codul Civil, Codul Penal și Codul de Procedură penală, Regulamentul 2016/679/UE, Directiva
                2016/680/CE, Directiva 2002/58/CE, alte acte normative si/sau administrative cu caracter normativ,
                subsecvente
                si specifice în materia executării legii în beneficiul colectivității locale.
            </p>
        </div>

        <div>
            <h3 class="title-content">
                9. Scopul prelucrărilor
            </h3>
            <p class="text-content">
                Primăria prelucrează datele tale personale în conformitate cu dispozițiile mai sus menționate și cu
                respectarea
                prevederilor GDPR, în calitate de operator, cu scopul general al facilitării, îmbunătățirii,
                eficientizării fluxului de
                comunicare dintre instituție și cetățeanul solicitant al beneficiilor/drepturilor individuale care pot
                face obiectul
                dispozițiilor legale enumerate în punctul 8 al prezentei.

            </p>
            <p class="text-content">
                Primăria prelucrează datele tale personale în conformitate cu dispozițiile mai sus menționate și cu
                respectarea
                prevederilor GDPR, în calitate de operator, cu scopul general al facilitării, îmbunătățirii,
                eficientizării fluxului de
                comunicare dintre instituție și cetățeanul solicitant al beneficiilor/drepturilor individuale care pot
                face obiectul
                dispozițiilor legale enumerate în punctul 8 al prezentei.
            </p>
        </div>

        <div>
            <h3 class="title-content">
                10. Tipuri de date cu caracter personal pe care le prelucrăm
            </h3>
            <p class="text-content">
                Politica privind protecția și securitatea datelor personale prelucrate de Primărie este de a colecta
                numai datele
                personale necesare, doar în scopurile menționate și de a solicita persoanelor vizate doar acele date cu
                caracter
                personal strict necesare îndeplinirii acestor scopuri.
            </p>
            <p class="text-content">
                <strong>Categoriile de date personale</strong> supuse prelucrărilor prin facilitățile digitale pot fi
                următoarele: date
                de
                identificare (care pot rezulta din cartea de identitate, certificat de naștere/certificat de deces,
                pașaport, hotărâre
                judecătorească), date de contact, date medicale, date financiare, date privind formarea profesională,
                date privind
                situația școlară, date privind apartenența etnică și/sau religioasă, date privind situația locativă,
                date privind
                proprietatea, starea civilă, starea judiciară, date audio, video, foto, semnătură digitală. Categoriille
                exacte de date
                utilizate sunt diferențiate în funcție de tipul serviciului public accesat de beneficiar.
            </p>
        </div>

        <div>
            <h3 class="title-content">
                11. Sursa datelor cu caracter personal
            </h3>
            <p class="text-content">
                <strong>Prin intermediul facilităților digitale, Primăria</strong> poate colecta datele tale personale
                direct de la tine sau de la
                reprezentanții tăi legali. În cazul în care, pe parcursul soluționării cererilor este necesar să
                prelucrăm date cu
                caracter personal obținute de la terți persoane juridice, aceștia din urmă au obligația de a-ți furniza
                informațiile
                necesare cu privire la utilizarea datelor cu caracter personal transmise către noi.
            </p>
        </div>

        <div>
            <h3 class="title-content">
                12. Categorii de destinatari ai datelor cu caracter personal
            </h3>
            <p class="text-content">
                Datele tale personale sunt destinate utilizării de către operator și pot fi comunicate următorilor
                destinatari, doar
                dacă este necesar:
            </p>
            <p class="text-content"><strong>a) alte instituții/autorități centrale și locale</strong></p>
            <p class="text-content"><strong>b) Alte entități private</strong> care colaborează conform legii cu
                Primăria, punctual și
                doar în limitele stabilite</p>
            <p class="text-content"><strong>c) Voluntari și/sau colaboratori autorizați</strong> care semnează
                angajament de
                confidențialitate.</p>
        </div>

        <div>
            <h3 class="title-content">
                13. Perioada de stocare a datelor personale
            </h3>
            <p class="text-content">
                Datele tale personale sunt stocate pe perioada necesară efectuării tuturor demersurilor necesare
                soluționării
                cererilor tale, după care vor fi arhivate potrivit legislației aplicabile pentru fiecare tip de
                activitate în parte conform
                dispozițiilor Legii Arhivelor Naţionale nr. 16/1996 cu modificările și completările ulterioare.
            </p>
        </div>

        <div>
            <h3 class="title-content">
                14. Drepturile tale și modul de exercitare al acestora
            </h3>
            <p class="text-content">
                În calitate de persoană vizată (conform definiției din capitolul III, articolele 14-25 din Regulamentul
                (UE)
                2018/1725), ai o serie de drepturi specifice, în special dreptul de a accesa datele tale cu caracter
                personal și de a le
                rectifica, dacă sunt inexacte sau incomplete. După caz, ai dreptul de a șterge datele tale cu caracter
                personal, de a
                restricționa prelucrarea lor sau de a te opune acesteia, precum și dreptul la portabilitatea datelor.
            </p>
            <p class="text-content">
                Drepturile tale conform Regulamentului GDPR sunt următoarele:
            </p>
            <ul>
                <li>Dreptul de retragere a consimțământului;</li>
                <li>Dreptul de a fi informat cu privire la prelucrarea datelor tale;</li>
                <li>Dreptul de acces asupra datelor;</li>
                <li>Dreptul de a rectifica datele inexacte sau incomplete;</li>
                <li>Dreptul de ștergere („dreptul de a fi uitat”);</li>
                <li>Dreptul la restricționarea prelucrării;</li>
                <li>Dreptul de a transmite datele pe care le avem despre tine către alt operator;</li>
                <li>Dreptul de a te opune prelucrării datelor;</li>
                <li>Dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automată, inclusiv
                    crearea de profiluri;</li>
                <li>Dreptul de a te adresa justiției;</li>
                <li>Dreptul de depune o plângere în fața unei Autorități de Supraveghere;</li>
            </ul>
        </div>

        <div>
            <h3 class="title-content">
                Te rugăm să reții că:
            </h3>
            <p class="text-content">
                Drepturile enumerate mai sus nu sunt absolute. Există excepții, de aceea fiecare cerere primită va fi
                analizată
                astfel încât să decidem dacă este întemeiată sau nu. În măsura în care cererea este întemeiată, îți vom
                facilita
                exercitarea drepturilor. Dacă cererea este neîntemeiată, o vom respinge, însă te vom informa asupra
                motivelor
                refuzului și asupra drepturilor de a depune o plângere la Autoritatea de Supraveghere și de a te adresa
                justiției. <strong>Îți
                    poți retrage consimțământul în orice moment pentru activitățile pentru care prelucrarea se face în
                    baza
                    consimțământului tău. Retragerea consimțământului însă nu va afecta în niciun fel prelucrarea
                    anterioară,
                    aceasta rămând perfect valabilă. </strong>
            </p>
            <p class="text-content"> Vom încerca să răspundem solicitării în termen de 30 de zile. Cu toate acestea,
                termenul poate fi
                prelungit în
                funcție de diferite aspecte, precum complexitatea cererii, numărul mare de cereri primite sau
                imposibilitatea de a te
                identifica într-un termen util.</p>
            <p class="text-content"> Dacă, deși depunem toate eforturile, nu reușim să te identificăm, iar tu nu ne
                furnizezi informații
                suplimentare
                pentru a reuși să te identificăm, nu suntem obligați să dăm curs solicitării.</p>
            <p class="text-content"> Dacă vrei să îți exerciți drepturile, o poți face prin transmiterea unei cereri
                scrise, semnate și
                datate la adresa de e-
                mail: <a (click)="navigateToNewTab('mailto:contact@ps4.ro', false)">{{'contact@ps4.ro'}}</a> <strong>,
                    prin utilizarea formularelor special concepute în acest scop și pe care
                    le
                    regăsești pe
                    site-ul instituției.</strong></p>
        </div>

        <div>
            <h3 class="title-content">
                15. Întrebări, solicitări și exercitarea drepturilor
            </h3>
            <p class="text-content">
                Dacă ai întrebări sau nelămuriri cu privire la prelucrarea informațiilor tale sau dorești să îți
                exerciți drepturile
                legale sau ai orice altă îngrijorare cu privire la confidențialitate, ne poți scrie la adresa de e-mail
                <a (click)="navigateToNewTab('mailto:contact@ps4.ro', false)">{{'contact@ps4.ro'}}</a>.
            </p>
            <p class="text-content">
                În cazul în care considerați că drepturile dumneavoastră prevăzute în Regulamentul (UE) 2018/1725 au
                fost
                încălcate ca urmare a prelucrării datelor dumneavoastră cu caracter personal de către operatorul de
                date, aveți
                dreptul de a sesiza Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal,
                Adresa: B-
                dul G-ral. Gheorghe Magheru 28-30, Sector 1, cod poștal 010336, București, România.
            </p>
            <p class="text-content">
                Pentru exercitarea drepturilor dvs. vă rugăm să contactați direct, responsabilul cu protecția datelor
                din Primăria
                la adresa: <a (click)="navigateToNewTab('mailto:contact@ps4.ro', false)">{{'contact@ps4.ro'}}</a>.
            </p>
        </div>
    </div>
</div>