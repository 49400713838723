import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormGroupDirective,
    NgForm,
    Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { finalize, forkJoin, from, Observable, catchError, map } from 'rxjs';
import Swal from 'sweetalert2';
import { helper } from 'src/environments/helper';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        );
    }
}

import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/services/notification.service';
import { ToastService } from 'src/app/services/toast.service';
import { AppFilesService } from 'src/app/services/app-files.service';
import { SolicitareType } from '../models';
import { PreviewDocumentModalComponent } from '../../../shared/preview-document-modal/preview-document-modal.component';
import { SolicitariService } from '../solicitari.service';
import { ClientService } from '../../programari-online/client.service';

@Component({
    selector: 'app-view-solicitare',
    templateUrl: './view-solicitare.component.html',
    styleUrls: ['./view-solicitare.component.scss'],
})
export class ViewSolicitareComponent implements OnInit {
    solicitareId: any;
    solicitareType: any;
    // general error
    errorTitle: string = environment.config.customNotifications.headers.error;
    errorIcon: string = environment.config.customNotifications.icons.error;
    errorType: string = environment.config.customNotifications.icons.error;
    // general success alert
    successTitle: string = environment.config.customNotifications.headers.success;
    successIcon: string = environment.config.customNotifications.icons.success;
    successType: string = environment.config.customNotifications.icons.success;

    public loading = false;
    matcher = new MyErrorStateMatcher();

    loaded: number = 0;
    solicitare: any = [];
    istoric: any = [];
    isLoading = false;
    form!: FormGroup;

    uploading: any = {
        documents: false,
    };
    files: any = {
        documents: [],
    };

    constructor(
        private solicitariService: SolicitariService,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        public dialog: MatDialog,
        private http: HttpClient,
        private toastService: ToastService,
        private appFileService: AppFilesService,
        private router: Router,
        private programariService: ClientService
    ) {
        this.initForm();
    }

    initForm() {
        this.form = new FormGroup({
            content: new FormControl('', Validators.compose([Validators.required])),
            documents: new FormControl('', []),
            documents_source: new FormControl('', []),
        });
    }

    ngOnInit(): void {
        this.solicitareId = this.route.snapshot.paramMap.get('solicitareId');
        this.solicitareType = this.route.snapshot.paramMap.get('solicitareType');
        this.getSolicitare();
    }

    get content() {
        return this.form.get('content');
    }

    async sendMessage() {
        if (this.isLoading) return;

        this.isLoading = true;
        try {
            this.solicitariService
                .saveMessageSolicitare(
                    this.solicitareType,
                    this.solicitareId,
                    this.form.value
                )
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe({
                    next: async (res: any) => {
                        let response =
                            typeof res.status_code !== 'undefined' ? res : res.error;
                        if (typeof response.status_code !== 'undefined') {
                            if (
                                response.status_code == 200 &&
                                typeof response.data !== 'undefined'
                            ) {
                                // all ok
                                this.form.reset({
                                    content: '',
                                    documents: '',
                                    documents_source: '',
                                });
                                await this.notificationService.warningSwal(
                                    this.successTitle,
                                    'Mesaj transmis cu succes.',
                                    this.successIcon
                                );
                                this.getSolicitare();

                                this.files['documents'] = [];
                            }
                        } else {
                            await this.notificationService.warningSwal(
                                this.errorTitle,
                                environment.config.customNotifications.generalMessages.error,
                                this.errorIcon
                            );
                        }
                    },
                    error: (res: any) => {
                        this.notificationService.handleHttpError(res.error);
                    },
                });
        } catch (err) {
            await this.notificationService.warningSwal(
                this.errorTitle,
                'Am intampinat o problema in procesarea informatiilor dvs. Va rugam sa reincercati sau sa reveniti mai tarziu.',
                this.errorIcon
            );
            this.isLoading = false;
        }
    }






    async upload(event: any, key: string) {
        const selectedFiles = (event.target as HTMLInputElement).files;
        if (!selectedFiles) {
            return;
        }

        this.uploading[key] = true;

        // Create an array of observables for each file upload
        const uploadObservables: Observable<any>[] = Array.from(selectedFiles).map((file) => {
            const formData = new FormData();
            formData.append('file', file, file.name);
            return this.solicitariService.fileUpload(this.solicitareType, formData);
        });

        // Use forkJoin to execute all upload observables in parallel
        forkJoin(uploadObservables).pipe(
            map((results: any[]) => {
                results.forEach((result: any, index: number) => {
                    let response = typeof result.status_code !== 'undefined' ? result : result.error;
                    if (typeof response.status_code !== 'undefined' && response.status_code == 200 &&
                        typeof response.data !== 'undefined') {
                        this.files[key].push(result.data);
                        const control = this.form.get(key + '_source');
                        if (control) {
                            control.setValue(JSON.stringify(this.files[key]));
                        }

                    } else {
                        this.notificationService.warningSwal(
                            this.errorTitle,
                            environment.config.customNotifications.generalMessages.fileErrorMsg,
                            this.errorIcon
                        );
                    }
                });
            }),
            catchError((error: any) => {
                console.error('An error occurred during file upload:', error);
                this.notificationService.handleHttpError(error);
                return [];
            })
        ).subscribe({
            complete: () => {
                // All uploads have completed
                this.toastService.openToast({
                    title: this.successTitle,
                    message: 'Încărcare efectuată cu succes.',
                    type: this.successIcon,
                });
                this.uploading[key] = false;
            }
        });
    }

    removeFile(key: string, index: number) {
        this.files[key].splice(index, 1);
        const control = this.form.get(key + '_source');
        if (control) {
            control.setValue(JSON.stringify(this.files[key]));
        }
    }

    async getSolicitare() {
        try {
            this.solicitariService
                .viewSolicitare(this.solicitareType, this.solicitareId)
                .subscribe({
                    next: async (res: any) => {
                        this.solicitare = res.data.cerere;
                        this.istoric = res.data.istoric;
                        this.loaded = 1;
                    },
                    error: (res: any) => {
                        this.notificationService.handleHttpError(res.error);
                    },
                });
        } catch (err) {
            console.log(err);
            await this.notificationService.warningSwal(
                this.errorTitle,
                'Am intampinat o problema in procesarea informatiilor dvs. Va rugam sa reincercati sau sa reveniti mai tarziu.',
                this.errorIcon
            );
        }
    }

    async downloadFile(idDocument: any) {
        let fullPath = this.getDownloadPath(this.solicitareType, idDocument);

        if (fullPath) {
            this.appFileService.downloadFile(fullPath);
        }
    }

    async downloadToClient(event: Event, idDocument: any, filename: string) {
        event.stopPropagation();

        let fullPath = this.getDownloadPath(this.solicitareType, idDocument);

        if (fullPath) {
            this.appFileService.downloadToClient(fullPath, filename);
        }
    }

    openViewDialog(event: Event, idDocument: any, extension: string) {
        event.stopPropagation();
        let fullPath = this.getDownloadPath(this.solicitareType, idDocument);

        const dialogRef = this.dialog.open(PreviewDocumentModalComponent, {
            width: '1000px',
            height: '70vh',
            panelClass: 'custom-dialog-container',
            data: {
                title: 'Vizualizare document',
                documentId: idDocument,
                apiPath: fullPath,
                extension: extension,
            },
            disableClose: true,
            autoFocus: false,
        });
    }

    getDownloadPath(solicitareType: string, idDocument: any) {
        const solicitareTypeEnum: SolicitareType =
            SolicitareType[solicitareType as keyof typeof SolicitareType];

        switch (solicitareTypeEnum) {
            case SolicitareType.Suport:
                return (
                    environment.interop.authCore.basePath +
                    environment.interop.authCore.solicitare.downloadDocumentSolicitare +
                    idDocument
                );
            case SolicitareType.DGITL:
                return (
                    environment.interop.dgitlCore.basePath +
                    environment.interop.dgitlCore.api.cetatean
                        .downloadDocumentSolicitare +
                    idDocument
                );
            case SolicitareType.AutorizariComerciale:
                return (
                    environment.interop.autcomCore.basePath +
                    environment.interop.autcomCore.api.cetatean.downloadDocumentSolicitare +
                    idDocument
                );
            case SolicitareType.Programari:
                return (
                    environment.interop.programariCore.basePath +
                    environment.interop.programariCore.api.cetatean.downloadDocumentSolicitare +
                    idDocument
                );
            default:
                return '';
        }
    }

    navigateToAddRequest() {
        const solicitareTypeEnum: SolicitareType =
            SolicitareType[this.solicitareType as keyof typeof SolicitareType];
        switch (solicitareTypeEnum) {
            case SolicitareType.Suport:
                this.router.navigate(['/dashboard/solicitari/institutie/urbanism']);
                break;
            case SolicitareType.DGITL:
                this.router.navigate(['/dashboard/solicitari/institutie/dgitl']);
                break;
            case SolicitareType.AutorizariComerciale:
                this.router.navigate(['/dashboard/solicitari/institutie/autorizari-comerciale']);
                break;
            case SolicitareType.Programari:
                this.router.navigate(['/dashboard/solicitari/programari-online/']);
                break;
            default:
                break;
        }
    }

    generareDraft() {
        this.loading = true;
        let fullPath = environment.interop.urbanismCore.basePath + 'cetatean/cerere-precompletare/' + this.solicitareId;
        this.solicitariService.generareDraft(fullPath)
            .subscribe({
                next: () => {
                    this.loading = false;
                },
                error: () => {
                    this.loading = false;
                }
            });
    }

    cancelAppointment(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să anulați programarea!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                // we do this because we need to have some control on activity
                this.cancelAppointmentAction(id);
            }
        });
    }

    cancelAppointmentAction(appointmentId: number) {
        this.loading = true;
        this.programariService.cancelAppointment(appointmentId)
            .then((res: any) => {
                this.loading = false;
                this.getSolicitare();

                if (res.errors == false) {
                    this.toastService.openToast(
                        {
                            title: this.successTitle,
                            message: 'Anulare efectuată cu succes.',
                            type: this.successType,
                        });
                }
            })
            .catch((err) => {
                this.loading = false;
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = 'Anulare eșuată: cererea dvs nu a putut fi anulată. Vă rugăm să reîncercați.';
                }

                this.toastService.openToast(
                    {
                        title: this.errorTitle,
                        message: msg,
                        type: this.errorType,
                    });
            });
    }
}
