import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-consimtamant-modal',
    templateUrl: './consimtamant-modal.component.html',
    styleUrls: ['./consimtamant-modal.component.scss']
})
export class ConsimtamantModalComponent {
    constructor(
        private dialogRef: MatDialogRef<ConsimtamantModalComponent>,
    ) { }

    closeDialog(data: any = null) {
        this.dialogRef.close({
            data: data
        });
    }

    navigateToNewTab(route: string, newTab: boolean = true) {
        const target = newTab ? '_blank' : '_self';
        window.open(route, target);
    }
}
