import { Component, OnInit, Inject, ViewChild, TemplateRef, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment'

import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber'
const phoneNumberUtil = PhoneNumberUtil.getInstance();

import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { ICountryOption, countriesList } from '../../shared/countries-list';
import { TermeniConditiiModalComponent } from 'src/app/shared/termeni-conditii-modal/termeni-conditii-modal.component';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { ConsimtamantModalComponent } from 'src/app/shared/consimtamant-modal/consimtamant-modal.component';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

@Component({
	selector: 'app-reset-pin-code',
	templateUrl: './reset-pin-code.component.html',
	styleUrls: ['./reset-pin-code.component.scss']
})
export class ResetPinCodeComponent implements OnInit {
	@Input() cnp_cui: number;

	// phone
	optionRom: ICountryOption = {
		name: 'Romania',
		flag: '🇷🇴',
		code: 'RO',
		dial_code: '+40',
	};

	currentOption = this.optionRom;
	options: ICountryOption[] = countriesList;

	changeCountry(option: ICountryOption) {
		this.currentOption = option;
	}

	// general error
	errorTitle: string = environment.config.customNotifications.headers.error
	errorIcon: string = environment.config.customNotifications.icons.error
	errorType: string = environment.config.customNotifications.icons.error
	// general success alert
	successTitle: string = environment.config.customNotifications.headers.success

	successIcon: string = environment.config.customNotifications.icons.success
	successType: string = environment.config.customNotifications.icons.success

	public loading = false;
	matcher = new MyErrorStateMatcher();

	formData: any = FormGroup

	uploading: any = {
		file_ci: false,
		file_cui: false,
		selfie_ci: false
	}

	phone_no: string = ''

	files: any = {
		file_ci: [],
		file_cui: [],
		selfie_ci: []
	}

	loaded: number = 1

	constructor(
		private authService: AuthService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<ResetPinCodeComponent>,
		public dialog: MatDialog,
		private dialogService: DialogService,
		private notificationService: NotificationService,
		public toastr: ToastrService
	) {
		dialogRef.disableClose = true;

		this.cnp_cui = data.cnp_cui

		this.formData = new FormGroup({
			nume: new FormControl('', Validators.compose([
				Validators.required
			])),
			cnp: new FormControl(this.cnp_cui, Validators.compose([
				Validators.required
			])),
			prenume: new FormControl('', Validators.compose([
				Validators.required
			])),
			f_nume: new FormControl(''),
			telefon: new FormControl('', Validators.compose([
				Validators.required
			])),
			email: new FormControl('', Validators.compose([
				Validators.required
			])),
			observatii: new FormControl(''),
			termeni_conditii: new FormControl(false, Validators.compose([
				Validators.requiredTrue
			])),
			declar_propria_rasp: new FormControl(false, Validators.compose([
				Validators.requiredTrue
			])),
			declar_acte: new FormControl(false, Validators.compose([
				Validators.requiredTrue
			])),
			consimtamant: new FormControl(false, Validators.compose([
				Validators.requiredTrue
			])),
			selfie_ci_source: new FormControl('', Validators.compose([
				Validators.required
			])),
			file_ci_source: new FormControl('', Validators.compose([
				Validators.required
			])),
			file_cui_source: new FormControl('', []),
		});
	}

	ngOnInit(): void {
	}

	// form data
	get nume() {
		return this.formData.get('nume');
	}

	get prenume() {
		return this.formData.get('prenume');
	}

	get cnp_cuiForm() {
		return this.formData.get('cnp');
	}

	get telefon() {
		return this.formData.get('telefon');
	}

	get phoneNumber() {
		return this.formData.get('phoneNumber');
	}

	get email() {
		return this.formData.get('email');
	}

	get observatii() {
		return this.formData.get('observatii');
	}

	cancel() {
		this.dialogRef.close({
			data: 'cancel'
		});
	}

	closeDialog(data: any) {
		this.dialogRef.close({
			data: data
		});
	}

	async submit() {
		var self = this
		this.loading = true
		var phone

		if (this.formData.value.telefon == null || this.formData.value.telefon == '') {
			self.loading = false
			await self.notificationService.warningSwal(this.errorTitle, 'Numarul de telefon este obligatoriu', this.errorIcon)
			return false;
		} else {
			const telephoneNoInput = this.currentOption.dial_code + this.formData.value.telefon;

			const phoneNo = phoneNumberUtil.parseAndKeepRawInput(telephoneNoInput)
			let regionCodeLower = '';
			let nationalNumberString = '';
			if (phoneNo) {
				const regionCode = phoneNumberUtil.getRegionCodeForNumber(phoneNo);
				if (regionCode) {
					regionCodeLower = (regionCode as string).toLowerCase();
				}

				const nationalNumber = phoneNo.getNationalNumber();
				if (nationalNumber) {
					nationalNumberString = nationalNumber.toString();
				}
			}
			phone = {
				dialCode: '',
				internationalNumber: telephoneNoInput,
				isoCode: regionCodeLower,
				nationalNumber: nationalNumberString
			}

			phone.dialCode = telephoneNoInput.replace(phone.nationalNumber, '')
			phone.nationalNumber = (phone.isoCode == 'ro' ? '0' + phone.nationalNumber : phone.nationalNumber)
		}

		const data = { ...this.formData.value, files: this.files, phone: phone }

		// return false
		// prepare processing data pls        
		try {
			self.authService.resetPINCodeInrolatAccount(data)
				.subscribe({
					next: (res: any) => {
						let response = (typeof res.status_code !== 'undefined' ? res : res.error)
						if (typeof response.status_code !== 'undefined') {
							if (response.status_code == 200 && typeof response.data !== 'undefined') {
								this.loading = false
								self.closeDialog(response.data)
							}
						} else {
							// add sentry
							let errorMessage = environment.config.customNotifications.generalMessages.error;
							self.notificationService.warningSwal(self.errorTitle, errorMessage, self.errorIcon);
							this.loading = false;
						}
					},
					error: (res: any) => {
						this.loading = false;
						this.onHttpError(res.error);
					}
				});
		} catch (err) {
			this.loading = false
			self.notificationService.warningSwal(this.errorTitle, 'Am intampinat o problema in procesarea informatiilor dvs. Va rugam sa reincercati sau sa reveniti mai tarziu.', this.errorIcon)
		}
		return false;
	}

	async upload(event: any, key: string) {
		const inputElement = event.target as HTMLInputElement;
		if (!inputElement.files) {
			return;
		}
		var self = this
		const files = inputElement.files;
		const file = files[0];
		const formData = new FormData();
		formData.append('file', file, file.name);

		this.uploading[key] = true;
		// self.files[key] = []

		return this.authService.fileUpload(formData)
			.subscribe({
				next: (result: any) => {
					let response = (typeof result.status_code !== 'undefined' ? result : result.error)
					if (typeof response.status_code !== 'undefined') {
						if (response.status_code == 200 && typeof response.data !== 'undefined') {
							self.files[key].push(result.data)
							const control = this.formData.get(key + '_source');
							if (control) {
								control.setValue(JSON.stringify(this.files[key]));
							}

							self.uploading[key] = false;
							self.openToast({ title: self.successTitle, message: 'Fisierul dvs a fost adaugat cu succes.', type: self.successIcon })
						}
					} else {
						// add sentry
						let errorMessage = environment.config.customNotifications.generalMessages.fileErrorMsg;
						self.notificationService.warningSwal(self.errorTitle, errorMessage, self.errorIcon);
						this.loading = false
						self.uploading[key] = false;
					}
				},
				error: (result: any) => {
					this.loading = false;
					self.uploading[key] = false;
					this.onHttpError(result.error);
				}
			});
	}

	clearInputFile(key: string) {
		let obj: Record<string, any> = {}
		obj[key + '_source'] = ''
		this.formData.patchValue(obj)
		this.files[key] = []
	}

	removeFile(key: string, index: number) {
		this.files[key].splice(index, 1)
		var self = this
		self.formData.get(key + '_source').setValue(JSON.stringify(self.files[key]));
	}

	openToast(object: any) {
		const { type, message, title } = object;

		let options = this.toastr.toastrConfig;
		options.progressBar = true;
		options.preventDuplicates = true;
		options.closeButton = true;

		const inserted = this.toastr.show(message, title, options, options.iconClasses[type]
		);
	}

	onHttpError(res: any, showErrorsInToast: boolean = false) {
		if (res.errors && res.errors.message && res.errors.message.length > 0) {
			res.errors.message.forEach((msg: string) => {
				if (showErrorsInToast) {
					this.openToast({ title: this.errorTitle, message: msg, type: this.errorType });
				}
				else {
					this.notificationService.warningSwal(this.errorTitle, msg, this.errorIcon);
				}
			});
		}
		else {
			let errorMessage = environment.config.customNotifications.generalMessages.error;
			this.notificationService.warningSwal(this.errorTitle, errorMessage, this.errorIcon);
		}
	}

	openTermsModal(slug: string): void {
		let configData = Object.assign({}, this.dialogService.getDialogConfig(), {
			data: {
				slug: slug,
			},
		});
		const dialogRef = this.dialog.open(
			TermeniConditiiModalComponent,
			configData
		);
	}

    openConsimtamantModal(): void {
        let configData = Object.assign({}, this.dialogService.getDialogConfig(), {
            data: {},
        });
        const dialogRef = this.dialog.open(
            ConsimtamantModalComponent,
            configData
        );
    }
}
