export const helper = {
    emailRegex:
        '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
    codeSMSRegex: /^[0-9]+$/,
    phoneRegex: '[0-9]{6,20}',
    cnpRegex: '[0-9]{13,13}',
    validatorsAccrossApp: {
        minPassword: 8,
        maxPassword: 50,
        emailMaxLength: 50,
        minStringLength: 2,
        maxStringLength: 60,
        minSmsCodeLength: 1,
        maxSmsCodeLength: 1,
    },
    dialogConfig: {
        colorButtons: {
            standard: '#DD6B55',
            danger: '#b92929',
            warning: '#dd7a24',
            success: '#2ab27b',
            info: '#5bc0de',
            primary: '#0275d8',
            secondary: '#6c757d',
            light: '#f7f7f7',
            dark: '#343a40',
            default: '#007bff',
        },
        icons: {
            success: 'success',
            error: 'error',
            info: 'info',
            warning: 'warning',
        },
        headers: {
            success: 'Felicitări',
            error: 'Atenție', // "Eroare",
            errorAtentie: 'Atenție',
            successForm: 'Felicitări!',
        },
        generalMessages: {
            error:
                'Ne pare rău, dar am întâmpinat o problemă. Vă rugăm să reîncercați. Dacă problema persistă, vă rugăm să ne contactați prin butonul de suport Tehnic.',
            fileErrorMsg:
                'Ne pare rău, dar fișierul selectat nu a putut fi încărcat. Vă rugăm să reîncercați!',
            pleaseWait: 'Vă rugăm așteptați!',
        },
        buttonLabels: {
            close: 'Închide',
            save: 'Salvează',
            ok: 'Ok',
            confirm: 'Confirm',
            retry: 'Reîncercă',
        },
        commonTitles: {
            areYouSure: 'Sunteți sigur?',
        },
    },
    modules: {
        dgitl: {
            taxaSpecialaDezvoltareUrbana: {
                sectionForms: {
                    sumarList: [
                        {
                            title: "Aplicabilitate",
                            id: "tsduInfo",
                            items: []
                        },
                        {
                            title: "Scutire taxă",
                            id: "tsduScutire",
                            items: []
                        },
                        {
                            title: "Alte Solicitări",
                            id: "tsduAlteSolicitari",
                            items: []
                        },
                        {
                            title: "Modalități plată",
                            id: "tsduPayment",
                            items: []
                        },
                        {
                            title: "Întebări frecvente",
                            id: "tsduFaq",
                            items: [
                                {
                                    title: "Cetățean cu rol fiscal",
                                    id: "tsduCetRolFiscal"
                                },
                                {
                                    title: "Cetățean cu rol fiscal, loc de parcare nominal",
                                    id: "tsduCetRolFiscalLocNominal"
                                },
                                {
                                    title: "Cetățenii fără rol fiscal",
                                    id: "tsduCetFaraRolFiscal"
                                }
                            ]
                        }
                    ],
                    alteSolicitariLabels: [
                        {
                            letter: 'D',
                            title: "Modificare/actualizare - număr înmatriculare",
                            catId: 9,
                            rowId: 59,
                            info: [

                            ],
                            documents: [
                                'Talon / Dovada actualizării / Modificării numărului de înmatriculare',
                                'Contract închiriere/comodat',
                            ],
                            formsAllowed: {
                                pf: true,
                                pj: true
                            }
                        },
                        {
                            letter: 'E',
                            title: "Solicitare restituire - taxă",
                            catId: 9,
                            rowId: 60,
                            info: [
                                "În cazul dobândirii unui loc de parcare nominal în Sectorul 4, taxa specială de dezvoltare urbană achitată anterior poate fi restituită",
                                "Este necesară completarea simbolului și a numărului de loc parcare din contractul emis de către Direcția Mobilitate Urbană"
                            ],
                            documents: [

                            ],
                            formsAllowed: {
                                pf: true,
                                pj: true
                            }
                        },
                        {
                            letter: 'F',
                            title: "Alte probleme/mențiuni",
                            catId: 9,
                            rowId: 61,
                            info: [
                                "Se completeaza / transmite catre D.G.I.T.L. doar în situații excepționale ce nu pot fi încadrate în celelalte categorii",
                                "Se atașează documentele justificative"
                            ],
                            documents: [

                            ],
                            formsAllowed: {
                                pf: true,
                                pj: true
                            }
                        }
                    ],
                    scutiriLabels: [
                        {
                            letter: 'A',
                            title: "Persoane cu dizabilități",
                            catId: 9,
                            rowId: 54,
                            url: '#',
                            info: [
                                "Certificatul de handicap poate fi permanent sau temporar"
                            ],
                            documents: [
                                'Certificat handicap'
                            ],
                            formsAllowed: {
                                pf: true,
                                pj: false
                            }
                        },
                        {
                            letter: 'B',
                            title: "Parcare proprietate personală",
                            catId: 9,
                            rowId: 55,
                            info: [

                            ],
                            documents: [
                                'Declarație',
                                'Autorizație construire',
                                'Documente cadastrale',
                                'Edificare'
                            ],
                            formsAllowed: {
                                pf: true,
                                pj: true
                            }
                        },
                        // {
                        //     letter: 'C',
                        //     title: "Parcare publică în afara Sectorului 4",
                        //     catId: 9,
                        //     rowId: 56,
                        //     info: [

                        //     ],
                        //     documents: [
                        //         'Declarație',
                        //         'Dovada parcării'
                        //     ],
                        //     formsAllowed: {
                        //         pf: true,
                        //         pj: true
                        //     }
                        // },
                        {
                            letter: 'C',
                            title: "Parcare privată utilizator",
                            catId: 9,
                            rowId: 57,
                            info: [

                            ],
                            documents: [
                                'Declarație',
                                'Contract închiriere',
                                'Comodat',
                            ],
                            formsAllowed: {
                                pf: true,
                                pj: true
                            }
                        },
                        // {
                        //     letter: 'E',
                        //     title: "Înstrăinare prin contract închiriere/comodat",
                        //     catId: 9,
                        //     rowId: 58,
                        //     info: [

                        //     ],
                        //     documents: [
                        //         // 'Declarație',
                        //         'Contract închiriere/comodat',
                        //     ],
                        //     formsAllowed: {
                        //         pf: true,
                        //         pj: true
                        //     }
                        // },
                        // {
                        //     letter: 'F',
                        //     title: "Parcare de reședință / publică cu abonament nominal - Sectorul 4",
                        //     catId: 9,
                        //     rowId: 62,
                        //     info: [
                        //         "Este necesară completarea simbolului și a numărului de loc parcare din contractul emis de către Direcția Mobilitate Urbană"
                        //     ],
                        //     documents: [

                        //     ],
                        //     formsAllowed: {
                        //         pf: true,
                        //         pj: true
                        //     }
                        // }
                    ]
                }
            }
        }
    }
};
