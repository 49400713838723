import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment'
import { ErrorStateMatcher } from '@angular/material/core';
import { TermeniConditiiModalComponent } from 'src/app/shared/termeni-conditii-modal/termeni-conditii-modal.component';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
const phoneNumberUtil = PhoneNumberUtil.getInstance();

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        );
    }
}

import { ToastrService } from 'ngx-toastr';

import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../utils/services/dialog.service';
import { SuportOnlineComponent } from '../../components/suport-online/suport-online.component';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { Router } from '@angular/router';
import { ICountryOption, countriesList } from '../../shared/countries-list';
import { ResetPinCodeComponent } from '../reset-pin-code/reset-pin-code.component';
import { ConfigExtensions } from 'src/app/utils/config-extensions';
import { ConsimtamantModalComponent } from 'src/app/shared/consimtamant-modal/consimtamant-modal.component';

@Component({
    selector: 'app-register-unique',
    templateUrl: './register-unique.component.html',
    styleUrls: ['./register-unique.component.scss'],
})
export class RegisterUniqueComponent implements OnInit {
    // phone
    optionRom: ICountryOption = {
        name: 'Romania',
        flag: '🇷🇴',
        code: 'RO',
        dial_code: '+40',
    };

    currentOption = this.optionRom;
    options: ICountryOption[] = countriesList;

    changeCountry(option: ICountryOption) {
        this.currentOption = option;
    }

    uploading: any = {
        file_ci: false,
        file_cui: false,
        selfie_ci: false,
    };

    files: any = {
        file_ci: [],
        file_cui: [],
        selfie_ci: [],
        selfie_cui: [],
    };

    env: any = environment;

    // general error
    errorTitle: string = environment.config.customNotifications.headers.error;
    errorIcon: string = environment.config.customNotifications.icons.error;
    errorType: string = environment.config.customNotifications.icons.error;
    // general success alert
    successTitle: string = environment.config.customNotifications.headers.success;
    successIcon: string = environment.config.customNotifications.icons.success;
    successType: string = environment.config.customNotifications.icons.success;

    public loading = false;
    matcher = new MyErrorStateMatcher();

    hide: boolean = true;
    hide_confirmation: boolean = true;
    allowResendSMS: boolean = true;
    token: string = '';

    form: FormGroup;
    formCode: FormGroup;
    formPIN: FormGroup;
    formCheck: FormGroup;
    formNewAccount: FormGroup;
    phone_no: string = '';

    step: number = -1;
    id_ticket: null | number = null;
    id_cetatean: null | number = null;

    // new stuff
    formType: number = 0;
    stepZone: number = 0;
    // 0 => only cnp show
    // 1 => pin preinrolare form, show pin insert + form with pin
    // 2 => form with new account!!!
    // 3 => pin code sms
    // 4 => success

    successMsgContCreatCuSucces =
        'Contul Dumneavoastră a fost creat cu succes. Vă puteți autentifica folosind adresa de email înregistrată sau CNP | CUI-ul introdus și parola aleasă.';
    successMsgValidareNecesara =
        'Pentru a beneficia de toate funcționalitățile aplicației este necesară validarea de către un operator a documentului de identitate atașat la înregistrare. Termenul maxim de validare este de 24-48 ore.';

    switchForm: number = 0;
    // 0 => default
    // 1 => pin preinrolare
    // 2 => cont nou!!!

    // Validator for at least one number character
    containsNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const hasNumber = /\d/.test(control.value);
            return hasNumber ? null : { noNumber: true };
        };
    }

    // Validator for at least one special character
    containsSpecialCharValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(control.value);
            return hasSpecialChar ? null : { noSpecialChar: true };
        };
    }

    containsUpperCaseValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const hasUpperCase = /[A-Z]/.test(control.value);
            return hasUpperCase ? null : { noUpperCase: true };
        };
    }

    constructor(
        private authService: AuthService,
        private notificationService: NotificationService,
        public toastr: ToastrService,
        public dialog: MatDialog,
        private dialogService: DialogService,
        private router: Router
    ) {
        this.form = new FormGroup({
            f_nume: new FormControl('', []),
            f_reg_com: new FormControl('', []),
            nume: new FormControl(
                '',
                Validators.compose([
                    Validators.minLength(
                        environment.config.validatorsAccrossApp.minStringLength
                    ),
                    Validators.maxLength(
                        environment.config.validatorsAccrossApp.maxStringLength
                    ),
                    Validators.required,
                ])
            ),
            prenume: new FormControl(
                '',
                Validators.compose([
                    Validators.minLength(
                        environment.config.validatorsAccrossApp.minStringLength
                    ),
                    Validators.maxLength(
                        environment.config.validatorsAccrossApp.maxStringLength
                    ),
                    Validators.required,
                ])
            ),
            telefon: new FormControl('', []),
            email: new FormControl('', []),
            password: new FormControl(
                '',
                Validators.compose([
                    Validators.minLength(
                        environment.config.validatorsAccrossApp.minPassword
                    ),
                    Validators.maxLength(
                        environment.config.validatorsAccrossApp.maxPassword
                    ),
                    Validators.required,
                    this.containsNumberValidator(),
                    this.containsSpecialCharValidator(),
                    this.containsUpperCaseValidator(),
                ])
            ),
            password_confirmation: new FormControl(
                '',
                Validators.compose([
                    Validators.minLength(
                        environment.config.validatorsAccrossApp.minPassword
                    ),

                    Validators.maxLength(
                        environment.config.validatorsAccrossApp.maxPassword
                    ),
                    Validators.required,
                    this.containsNumberValidator(),
                    this.containsSpecialCharValidator(),
                    this.containsUpperCaseValidator(),
                    this.passwordMatchValidator()
                ])
            ),
            termeni_conditii: new FormControl(
                false,
                Validators.compose([Validators.requiredTrue])
            ),
            declar_propria_rasp: new FormControl(
                false,
                Validators.compose([Validators.requiredTrue])
            ),
            declar_acte: new FormControl(
                false,
                Validators.compose([Validators.requiredTrue])
            ),
            consimtamant: new FormControl(
                false,
                Validators.compose([Validators.requiredTrue])
            ),
            cnp: new FormControl('', [
                Validators.minLength(2),
                Validators.maxLength(13),
                Validators.required,
            ]),
            id_inrolare: new FormControl('', []),
        });

        this.formCode = new FormGroup({
            code: new FormControl(
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(5),
                ])
            ),
        });

        this.formPIN = new FormGroup({
            pin: new FormControl(
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(14),
                ])
            ),
            cnp_cui: new FormControl(
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(13),
                ])
            ),
        });

        this.formCheck = new FormGroup({
            cnp_cui: new FormControl(
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(13),
                ])
            ),
        });

        this.formNewAccount = new FormGroup({
            f_nume: new FormControl('', []),
            nume: new FormControl(
                '',
                Validators.compose([
                    Validators.minLength(
                        environment.config.validatorsAccrossApp.minStringLength
                    ),
                    Validators.maxLength(
                        environment.config.validatorsAccrossApp.maxStringLength
                    ),
                    Validators.required,
                ])
            ),
            prenume: new FormControl(
                '',
                Validators.compose([
                    Validators.minLength(
                        environment.config.validatorsAccrossApp.minStringLength
                    ),
                    Validators.maxLength(
                        environment.config.validatorsAccrossApp.maxStringLength
                    ),
                    Validators.required,
                ])
            ),
            telefon: new FormControl('', Validators.compose([Validators.required])),
            email: new FormControl(
                '',
                Validators.compose([
                    Validators.pattern(environment.config.emailRegex),
                    Validators.maxLength(
                        environment.config.validatorsAccrossApp.emailMaxLength
                    ),
                    Validators.required,
                    Validators.email,
                ])
            ),
            password: new FormControl(
                '',
                Validators.compose([
                    Validators.minLength(
                        environment.config.validatorsAccrossApp.minPassword
                    ),
                    Validators.maxLength(
                        environment.config.validatorsAccrossApp.maxPassword
                    ),
                    Validators.required,
                    this.containsNumberValidator(),
                    this.containsSpecialCharValidator(),
                    this.containsUpperCaseValidator(),
                ])
            ),
            password_confirmation: new FormControl(
                '',
                Validators.compose([
                    Validators.minLength(
                        environment.config.validatorsAccrossApp.minPassword
                    ),
                    Validators.maxLength(
                        environment.config.validatorsAccrossApp.maxPassword
                    ),
                    Validators.required,
                    this.containsNumberValidator(),
                    this.containsSpecialCharValidator(),
                    this.containsUpperCaseValidator(),
                    this.passwordMatchValidator()


                ])
            ),
            termeni_conditii: new FormControl(
                false,
                Validators.compose([Validators.requiredTrue])
            ),
            declar_acte: new FormControl(
                false,
                Validators.compose([Validators.requiredTrue])
            ),
            declar_propria_rasp: new FormControl(
                false,
                Validators.compose([Validators.requiredTrue])
            ),
            consimtamant: new FormControl(
                false,
                Validators.compose([Validators.requiredTrue])
            ),
            cnp: new FormControl('', [
                Validators.minLength(3),
                Validators.maxLength(13),
                Validators.required,
            ]),
            file_ci: new FormControl('', []),
            file_ci_source: new FormControl(
                '',
                Validators.compose([Validators.required])
            ),
            file_cui: new FormControl('', []),
            file_cui_source: new FormControl('', []),
            selfie_ci: new FormControl('', []),
            selfie_ci_source: new FormControl(
                '',
                Validators.compose([Validators.required])
            ),
        });
    }

    passwordMatchValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const form = control.parent;
            if (!form) return null;

            const password = form.get('password')?.value;
            const confirmPassword = control.value;

            return password === confirmPassword ? null : { mismatch: true };
        };
    }


    ngOnInit(): void { }

    get email() {
        return this.form.get('email');
    }

    get codeInput() {
        return this.formCode.get('code');
    }

    get password() {
        return this.form.get('password');
    }

    get passwordConfirmation() {
        return this.form.get('password_confirmation');
    }

    get prenume() {
        return this.form.get('prenume');
    }

    get nume() {
        return this.form.get('nume');
    }

    get telefon() {
        return this.form.get('telefon');
    }

    get phoneNumber() {
        return this.form.get('phoneNumber');
    }

    get cnp() {
        return this.form.get('cnp');
    }

    get pin() {
        return this.formPIN.get('pin');
    }

    get cnpPIN() {
        return this.formPIN.get('cnp_cui');
    }

    get cnpStatus() {
        return this.formCheck.get('cnp_cui');
    }

    // new account!!!
    get emailNewAccount() {
        return this.formNewAccount.get('email');
    }

    get passwordNewAccount() {
        return this.formNewAccount.get('password');
    }

    get passwordConfirmationNewAccount() {
        return this.formNewAccount.get('password_confirmation');
    }

    get prenumeNewAccount() {
        return this.formNewAccount.get('prenume');
    }

    get numeNewAccount() {
        return this.formNewAccount.get('nume');
    }

    get telefonNewAccount() {
        return this.formNewAccount.get('telefon');
    }

    get phoneNumberNewAccount() {
        return this.formNewAccount.get('phoneNumber');
    }

    get cnpNewAccount() {
        return this.formNewAccount.get('cnp');
    }

    async checkPIN() {
        // we need to start first
        this.loading = true;

        try {
            if (!this.formPIN.valid) {
                this.loading = false;
                await this.notificationService.warningSwal(
                    this.errorTitle,
                    'Va rugam sa completati toate campurile',
                    this.errorIcon
                );
                return false;
            } else {
                // success
                if (
                    this.formPIN.value.cnp_cui == null ||
                    this.formPIN.value.cnp_cui.toString().length < 3 ||
                    this.formPIN.value.cnp_cui.toString().length > 13
                ) {
                    this.loading = false;
                    await this.notificationService.warningSwal(
                        this.errorTitle,
                        'Campul CNP/CUI este obligatoriu',
                        this.errorIcon
                    );
                    return false;
                }

                if (
                    this.formPIN.value.pin == null ||
                    (this.formPIN.value.pin.toString().length > 14 &&
                        this.formPIN.value.pin.toString().length < 8)
                ) {
                    this.loading = false;
                    await this.notificationService.warningSwal(
                        this.errorTitle,
                        'Campul COD UNIC este obligatoriu, și trebuie sa conțină între 8 și 14 caractere',
                        this.errorIcon
                    );
                    return false;
                }

                this.authService.checkPinRegister(this.formPIN.value).subscribe({
                    next: (res: any) => {
                        let response =
                            typeof res.status_code !== 'undefined' ? res : res.error;
                        if (typeof response.status_code !== 'undefined') {
                            if (
                                response.status_code == 200 &&
                                typeof response.data !== 'undefined'
                            ) {
                                this.loading = false;

                                let obj = {
                                    title: this.successTitle,
                                    message:
                                        'Contul dvs a fost identificat. Vă rugăm să verificați/completați datele afișate și să confirmați înregistrarea contului dvs.',
                                    type: this.successIcon,
                                };
                                this.openToast(obj);

                                this.form.patchValue({
                                    nume: response.data.nume,
                                    prenume: response.data.prenume,
                                    cnp: response.data.cnp.toString(),
                                    telefon:
                                        response.data.telefon == null || response.data.telefon == ''
                                            ? null
                                            : parseInt(response.data.telefon).toString(),
                                    email: response.data.email,
                                    f_nume: response.data.f_nume,
                                    f_reg_com: response.data.f_reg_com,
                                    id_inrolare: response.data.id_inrolare,
                                });

                                this.switchForm = 1;
                                return false;
                            }
                        }
                        return false;
                    },
                    error: (res: any) => {
                        this.loading = false;
                        this.onHttpError(res.error);
                    },
                });
            }
        } catch (err) {
            this.loading = false;
            console.log(err);
            await this.notificationService.warningSwal(
                this.errorTitle,
                'Am intampinat o problema in procesarea informatiilor dvs. Va rugam sa reincercati sau sa reveniti mai tarziu.',
                this.errorIcon
            );
        }

        return false;
    }

    async store() {
        // we need to start first
        this.loading = true;
        var phone;

        try {
            if (!this.form.valid) {
                this.loading = false;
                await this.notificationService.warningSwal(
                    this.errorTitle,
                    'Va rugam sa completati toate campurile',
                    this.errorIcon
                );
                return false;
            } else {
                // now proceed with the actual validation

                // validate passwords
                if (
                    this.form.value.password == '' ||
                    this.form.value.password.length < 7 ||
                    this.form.value.password.length > 50
                ) {
                    this.loading = false;
                    await this.notificationService.warningSwal(
                        this.errorTitle,
                        'Parola introdusă trebuie sa fie între 8 și 50 de caractere.',
                        this.errorIcon
                    );
                    return false;
                }

                if (
                    this.form.value.password_confirmation == '' ||
                    this.form.value.password_confirmation.length < 7 ||
                    this.form.value.password_confirmation.length > 50
                ) {
                    this.loading = false;
                    await this.notificationService.warningSwal(
                        this.errorTitle,
                        'Parola de confirmare introdusă trebuie sa fie între 8 și 50 de caractere.',
                        this.errorIcon
                    );
                    return false;
                }

                if (
                    this.form.value.password !== this.form.value.password_confirmation
                ) {
                    this.loading = false;
                    await this.notificationService.warningSwal(
                        this.errorTitle,
                        'Parolele introduse nu sunt la fel. Vă rugăm să verificați parolele introduse.',
                        this.errorIcon
                    );
                    return false;
                }

                let emailReg = new RegExp(environment.config.emailRegex);
                if (
                    (this.form.value.email !== '' &&
                        !emailReg.test(this.form.value.email)) ||
                    this.form.value.email.length > 70
                ) {
                    this.loading = false;
                    await this.notificationService.warningSwal(
                        this.errorTitle,
                        'Vă rugăm să introduceți o adresă de email validă.',
                        this.errorIcon
                    );
                    return false;
                }

                if (this.form.value.telefon == null || this.form.value.telefon == '') {
                    this.loading = false;
                    await this.notificationService.warningSwal(
                        this.errorTitle,
                        'Numarul de telefon este obligatoriu',
                        this.errorIcon
                    );
                    return false;
                } else {
                    const telephoneNoInput =
                        this.currentOption.dial_code + this.form.value.telefon;
                    const phoneNo =
                        phoneNumberUtil.parseAndKeepRawInput(telephoneNoInput);
                    let regionCodeLower = '';
                    let nationalNumberString = '';
                    if (phoneNo) {
                        const regionCode = phoneNumberUtil.getRegionCodeForNumber(phoneNo);
                        if (regionCode) {
                            regionCodeLower = (regionCode as string).toLowerCase();
                        }

                        const nationalNumber = phoneNo.getNationalNumber();
                        if (nationalNumber) {
                            nationalNumberString = nationalNumber.toString();
                        }
                    }

                    phone = {
                        dialCode: '',
                        internationalNumber: telephoneNoInput,
                        isoCode: regionCodeLower,
                        nationalNumber: nationalNumberString,
                    };

                    phone.dialCode = telephoneNoInput.replace(phone.nationalNumber, '');
                    phone.nationalNumber =
                        phone.isoCode == 'ro'
                            ? '0' + phone.nationalNumber
                            : phone.nationalNumber;
                }

                if (
                    this.form.value.cnp == null ||
                    this.form.value.cnp.toString().length < 3 ||
                    this.form.value.cnp.toString().length > 13
                ) {
                    this.loading = false;
                    await this.notificationService.warningSwal(
                        this.errorTitle,
                        'Campul CNP/CUI este obligatoriu',
                        this.errorIcon
                    );
                }

                let lengthCI = this.form.value.cnp.toString().length;
                switch (true) {
                    case lengthCI < 13:
                        // possible CUI
                        if (
                            typeof this.form.value.f_nume == 'undefined' ||
                            this.form.value.f_nume.length < 2
                        ) {
                            // Company Required
                            this.loading = false;
                            await this.notificationService.warningSwal(
                                this.errorTitle,
                                "Vă rugăm să completați câmpul 'Nume Companie'",
                                this.errorIcon
                            );
                            return false;
                        }
                        break;

                    case lengthCI > 13:
                        // other kind of scenarios
                        this.loading = false;
                        await this.notificationService.warningSwal(
                            this.errorTitle,
                            'CI/CUI introdus este invalid',
                            this.errorIcon
                        );
                        return false;

                    default:
                        // do nothing pls
                        break;
                }

                const dataObj = Object.assign({}, this.form.value, { phone: phone });

                this.authService.registerAccountInrolat(dataObj).subscribe({
                    next: (res: any) => {
                        let response =
                            typeof res.status_code !== 'undefined' ? res : res.error;
                        if (typeof response.status_code !== 'undefined') {
                            if (
                                response.status_code == 200 &&
                                typeof response.data !== 'undefined'
                            ) {
                                this.loading = false;

                                let obj = {
                                    title: this.successTitle,
                                    message:
                                        'Contul dvs a fost înregistrat. Verificarea numărului de telefon prin cod este obligatorie. Codul pin prin sms a fost trimis.',
                                    type: this.successIcon,
                                };
                                this.openToast(obj);

                                this.id_cetatean = response.data.id_cet;
                                this.id_ticket = response.data.id;
                                this.phone_no = response.data.phone_no;

                                this.stepZone = 3;
                            }
                        } else {
                            // add sentry
                            let errorMessage =
                                environment.config.customNotifications.generalMessages.error;
                            this.loading = false;
                            this.notificationService.warningSwal(
                                this.errorTitle,
                                errorMessage,
                                this.errorIcon
                            );
                        }
                    },
                    error: (res: any) => {
                        this.loading = false;
                        this.onHttpError(res.error);
                    },
                });
            }
        } catch (err) {
            this.loading = false;
            console.log(err);
            await this.notificationService.warningSwal(
                this.errorTitle,
                'Am intampinat o problema in procesarea informatiilor dvs. Va rugam sa reincercati sau sa reveniti mai tarziu.',
                this.errorIcon
            );
        }

        return false;
    }

    resendCode() {
        var self = this;

        if (self.allowResendSMS) {
            self.authService
                .resendCode({ id: self.id_cetatean, format: 'sms' })
                .subscribe({
                    next: (res: any) => {
                        let response =
                            typeof res.status_code !== 'undefined' ? res : res.error;
                        if (typeof response.status_code !== 'undefined') {
                            if (
                                response.status_code == 200 &&
                                typeof response.data !== 'undefined'
                            ) {
                                self.allowResendSMS = false;
                                self.openToast({
                                    title: self.successTitle,
                                    message: 'Cod-ul a fost retrimis prin SMS',
                                    type: self.successType,
                                });

                                setTimeout(() => {
                                    self.allowResendSMS = true;
                                }, 60000);
                            }
                        } else {
                            // general error
                            let errorMessage =
                                environment.config.customNotifications.generalMessages.error;
                            self.loading = false;
                            self.openToast({
                                title: self.errorTitle,
                                message: errorMessage,
                                type: self.errorType,
                            });
                        }
                    },
                    error: (res: any) => {
                        this.loading = false;
                        this.onHttpError(res.error, true);
                    },
                });
        } else {
            self.openToast({
                title: self.errorTitle,
                message: 'Puteti trimite urmatorul cod prin sms in cca 1 minut',
                type: self.errorType,
            });
            return false;
        }

        return false;
    }

    async confirmCode() {
        var self = this;

        if (!this.formCode.valid) {
            await self.notificationService.warningSwal(
                this.errorTitle,
                'Va rugam sa completati codul primit prin sms.',
                this.errorIcon
            );
            return false;
        } else {
            // we have a valid form
            self.authService
                .confirmCode({ id: self.id_cetatean, code: self.formCode.value.code })
                .subscribe({
                    next: (res: any) => {
                        let response =
                            typeof res.status_code !== 'undefined' ? res : res.error;
                        if (typeof response.status_code !== 'undefined') {
                            if (
                                response.status_code == 200 &&
                                typeof response.data !== 'undefined'
                            ) {
                                // success
                                this.stepZone = 4;
                            }
                        } else {
                            // general error
                            let errorMessage =
                                environment.config.customNotifications.generalMessages.error;
                            self.loading = false;
                            self.openToast({
                                title: self.errorTitle,
                                message: errorMessage,
                                type: self.errorType,
                            });
                        }
                    },
                    error: (res: any) => {
                        this.loading = false;
                        this.onHttpError(res.error, true);
                    },
                });
        }

        return false;
    }

    openDialogSuportOnline(FAQSection: string) {
        let configData = Object.assign({}, this.dialogService.getDialogConfig(), {
            data: {
                PageSection: FAQSection,
            },
        });
        const dialogRef = this.dialog.open(SuportOnlineComponent, configData);

        dialogRef.afterClosed().subscribe((res: any) => {
            const response =
                typeof res.data !== 'undefined' &&
                    res.data !== null &&
                    typeof res.data.id !== 'undefined'
                    ? res.data
                    : null;

            if (response !== null) {
                // show success stuff and reload data
                let message =
                    'Solicitarea dvs cu numarul ' +
                    response.id +
                    '/' +
                    response.date +
                    ' a fost trimisa cu succes. ';
                this.notificationService.warningSwal(
                    this.successTitle,
                    message,
                    this.successIcon
                );
            }
        });
    }

    isProduction() {
        return environment.production;
        // return ConfigExtensions.isProductionWithLimitedFeatures();
    }

    // new zone
    async checkStatusCNP() {
        // we need to start first
        this.loading = true;
        var self = this;

        try {
            if (!this.formCheck.valid) {
                self.loading = false;
                await self.notificationService.warningSwal(
                    this.errorTitle,
                    'Va rugam sa completati toate campurile',
                    this.errorIcon
                );
                return false;
            } else {
                // success
                if (
                    this.formCheck.value.cnp_cui == null ||
                    this.formCheck.value.cnp_cui.toString().length < 3 ||
                    this.formCheck.value.cnp_cui.toString().length > 13
                ) {
                    self.loading = false;
                    await self.notificationService.warningSwal(
                        this.errorTitle,
                        'Campul CNP/CUI este obligatoriu',
                        this.errorIcon
                    );
                    return false;
                }

                this.authService.checkUserCNP(this.formCheck.value).subscribe({
                    next: async (res: any) => {
                        let response =
                            typeof res.status_code !== 'undefined' ? res : res.error;
                        if (typeof response.status_code !== 'undefined') {
                            if (
                                response.status_code == 200 &&
                                typeof response.data !== 'undefined'
                            ) {
                                self.loading = false;

                                let objMsg =
                                    response.data == 1
                                        ? 'Contul dumneavoastră a fost identificat. Vă rugăm să completați parola aferentă notificării primite (scrisorii) din partea DGITL/notificării DGUAT Sector 4.'
                                        : 'Contul dumneavoastră nu a fost identificat. Vă rugăm să completați informațiile de mai jos pentru a putea înregistra un cont nou.';

                                if (response.data == 1) {
                                    // preinrolat
                                    self.formPIN.patchValue({
                                        cnp_cui: self.formCheck.value.cnp_cui,
                                    });
                                    self.stepZone = response.data;
                                    self.notificationService.warningSwal(
                                        self.successTitle,
                                        objMsg,
                                        self.successIcon
                                    );

                                    self.formType = response.data;
                                } else {
                                    // new account
                                    if (this.isProduction()) {
                                        self.formType = response.data;
                                        self.formNewAccount.patchValue({
                                            cnp: self.formCheck.value.cnp_cui,
                                        });
                                        this.stepZone = response.data;
                                        self.notificationService.warningSwal(
                                            self.successTitle,
                                            objMsg,
                                            self.successIcon
                                        );
                                    } else {
                                        await self.notificationService
                                            .warningSwal(
                                                self.successTitle,
                                                'Contul dumneavoastră nu a fost identificat. Doriti înregistrarea prin OCR-izarea CI ?',
                                                self.successIcon,
                                                'Da',
                                                'Nu',
                                                true
                                            )
                                            .then((result) => {
                                                self.formType = response.data;
                                                self.formNewAccount.patchValue({
                                                    cnp: self.formCheck.value.cnp_cui,
                                                });
                                                if (result.isConfirmed) {
                                                    this.stepZone = 21;
                                                } else {
                                                    this.stepZone = response.data;
                                                }
                                            });
                                    }
                                }
                            }
                        } else {
                            // add sentry
                            let errorMessage =
                                environment.config.customNotifications.generalMessages.error;
                            self.loading = false;
                            self.notificationService.warningSwal(
                                self.errorTitle,
                                errorMessage,
                                self.errorIcon
                            );
                        }
                    },
                    error: (res: any) => {
                        this.loading = false;
                        this.onHttpError(res.error);
                    },
                });
            }
        } catch (err) {
            self.loading = false;
            console.log(err);
            await self.notificationService.warningSwal(
                this.errorTitle,
                'Am intampinat o problema in procesarea informatiilor dvs. Va rugam sa reincercati sau sa reveniti mai tarziu.',
                this.errorIcon
            );
        }
        return false;
    }

    async storeNewAccount() {
        // we need to start first
        this.loading = true;
        var self = this;
        var phone;

        try {
            // console.log(this.formNewAccount.value);
            if (!this.formNewAccount.valid) {
                self.loading = false;
                await self.notificationService.warningSwal(
                    this.errorTitle,
                    'Va rugam sa completati toate campurile',
                    this.errorIcon
                );
                return false;
            } else {
                // now proceed with the actual validation

                // validate passwords
                if (
                    this.formNewAccount.value.password == '' ||
                    this.formNewAccount.value.password.length < 7 ||
                    this.formNewAccount.value.password.length > 50
                ) {
                    self.loading = false;
                    await self.notificationService.warningSwal(
                        this.errorTitle,
                        'Parola introdusă trebuie sa fie între 8 și 50 de caractere.',
                        this.errorIcon
                    );
                    return false;
                }

                if (
                    this.formNewAccount.value.password_confirmation == '' ||
                    this.formNewAccount.value.password_confirmation.length < 7 ||
                    this.formNewAccount.value.password_confirmation.length > 50
                ) {
                    self.loading = false;
                    await self.notificationService.warningSwal(
                        this.errorTitle,
                        'Parola de confirmare introdusă trebuie sa fie între 8 și 50 de caractere.',
                        this.errorIcon
                    );
                    return false;
                }

                if (
                    this.formNewAccount.value.password !==
                    this.formNewAccount.value.password_confirmation
                ) {
                    self.loading = false;
                    await self.notificationService.warningSwal(
                        this.errorTitle,
                        'Parolele introduse nu sunt la fel. Vă rugăm să verificați parolele introduse.',
                        this.errorIcon
                    );
                    return false;
                }

                let emailReg = new RegExp(environment.config.emailRegex);
                if (
                    !emailReg.test(this.formNewAccount.value.email) ||
                    this.formNewAccount.value.email.length > 70
                ) {
                    self.loading = false;
                    await self.notificationService.warningSwal(
                        this.errorTitle,
                        'Vă rugăm să introduceți o adresă de email validă.',
                        this.errorIcon
                    );
                    return false;
                }

                if (
                    this.formNewAccount.value.telefon == null ||
                    this.formNewAccount.value.telefon == ''
                ) {
                    self.loading = false;
                    await self.notificationService.warningSwal(
                        this.errorTitle,
                        'Numarul de telefon este obligatoriu',
                        this.errorIcon
                    );
                    return false;
                } else {
                    const telephoneNoInput =
                        this.currentOption.dial_code + this.formNewAccount.value.telefon;
                    const phoneNo =
                        phoneNumberUtil.parseAndKeepRawInput(telephoneNoInput);
                    let regionCodeLower = '';
                    let nationalNumberString = '';
                    if (phoneNo) {
                        const regionCode = phoneNumberUtil.getRegionCodeForNumber(phoneNo);
                        if (regionCode) {
                            regionCodeLower = (regionCode as string).toLowerCase();
                        }

                        const nationalNumber = phoneNo.getNationalNumber();
                        if (nationalNumber) {
                            nationalNumberString = nationalNumber.toString();
                        }
                    }

                    phone = {
                        dialCode: '',
                        internationalNumber: telephoneNoInput,
                        isoCode: regionCodeLower,
                        nationalNumber: nationalNumberString,
                    };

                    phone.dialCode = telephoneNoInput.replace(phone.nationalNumber, '');
                    phone.nationalNumber =
                        phone.isoCode == 'ro'
                            ? '0' + phone.nationalNumber
                            : phone.nationalNumber;
                }

                if (
                    this.formNewAccount.value.cnp == null ||
                    this.formNewAccount.value.cnp.toString().length < 3 ||
                    this.formNewAccount.value.cnp.toString().length > 13
                ) {
                    self.loading = false;
                    await self.notificationService.warningSwal(
                        this.errorTitle,
                        'Campul CNP/CUI este obligatoriu',
                        this.errorIcon
                    );
                }

                let lengthCI = this.formNewAccount.value.cnp.toString().length;
                switch (true) {
                    case lengthCI == 13:
                        // classic CI
                        // please check value of CI
                        if (
                            typeof self.formNewAccount.value.file_ci_source == 'undefined' ||
                            self.formNewAccount.value.file_ci_source == ''
                        ) {
                            // CI Document Required
                            self.loading = false;
                            await self.notificationService.warningSwal(
                                this.errorTitle,
                                'Vă rugăm să atașați o copie CI',
                                this.errorIcon
                            );
                            return false;
                        }

                        if (
                            typeof self.formNewAccount.value.selfie_ci_source ==
                            'undefined' ||
                            self.formNewAccount.value.selfie_ci_source == ''
                        ) {
                            // CI Document Required
                            self.loading = false;
                            await self.notificationService.warningSwal(
                                this.errorTitle,
                                'Vă rugăm să atașați selfie cu CI-ul dumneavoastra',
                                this.errorIcon
                            );
                            return false;
                        }
                        break;

                    case lengthCI < 13:
                        // possible CUI
                        if (
                            typeof self.formNewAccount.value.f_nume == 'undefined' ||
                            self.formNewAccount.value.f_nume.length < 2
                        ) {
                            // Company Required
                            self.loading = false;
                            await self.notificationService.warningSwal(
                                this.errorTitle,
                                "Vă rugăm să completați câmpul 'Nume Companie'",
                                this.errorIcon
                            );
                            return false;
                        } else if (
                            typeof self.formNewAccount.value.file_cui_source == 'undefined' ||
                            self.formNewAccount.value.file_cui_source == ''
                        ) {
                            // CUI Document Required
                            self.loading = false;
                            await self.notificationService.warningSwal(
                                this.errorTitle,
                                'Va rugăm să atașați o copie CUI',
                                this.errorIcon
                            );
                            return false;
                        } else if (
                            typeof self.formNewAccount.value.file_ci_source == 'undefined' ||
                            self.formNewAccount.value.file_ci_source == ''
                        ) {
                            // CI Document Required
                            self.loading = false;
                            await self.notificationService.warningSwal(
                                this.errorTitle,
                                'Vă rugăm să atașați o copie CI',
                                this.errorIcon
                            );
                            return false;
                        } else if (
                            typeof self.formNewAccount.value.selfie_ci_source ==
                            'undefined' ||
                            self.formNewAccount.value.selfie_ci_source == ''
                        ) {
                            // CUI Document Required
                            self.loading = false;
                            await self.notificationService.warningSwal(
                                this.errorTitle,
                                'Va rugăm să atașați selfie cu CI-ul dumneavoastra',
                                this.errorIcon
                            );
                            return false;
                        }
                        break;

                    case lengthCI > 13:
                        // other kind of scenarios
                        self.loading = false;
                        await self.notificationService.warningSwal(
                            this.errorTitle,
                            'CI/CUI introdus este invalid',
                            this.errorIcon
                        );
                        return false;
                }

                const dataObj = Object.assign({}, self.formNewAccount.value, {
                    phone: phone,
                });

                this.authService.registerAccount(dataObj).subscribe({
                    next: (res: any) => {
                        let response =
                            typeof res.status_code !== 'undefined' ? res : res.error;
                        if (typeof response.status_code !== 'undefined') {
                            if (
                                response.status_code == 200 &&
                                typeof response.data !== 'undefined'
                            ) {
                                self.loading = false;

                                let obj = {
                                    title: self.successTitle,
                                    message:
                                        'Contul dvs a fost înregistrat. Verificarea numărului de telefon prin cod este obligatorie. Codul pin prin sms a fost trimis.',
                                    type: self.successIcon,
                                };
                                self.openToast(obj);

                                self.id_cetatean = response.data.id_cet;
                                self.id_ticket = response.data.id;
                                self.phone_no = response.data.phone_no;

                                this.stepZone = 3;
                            }
                        } else {
                            // add sentry
                            let errorMessage =
                                environment.config.customNotifications.generalMessages.error;
                            self.loading = false;
                            self.notificationService.warningSwal(
                                self.errorTitle,
                                errorMessage,
                                self.errorIcon
                            );
                        }
                    },
                    error: (res: any) => {
                        this.loading = false;
                        this.onHttpError(res.error);
                        return false;
                    },
                });
            }
        } catch (err) {
            self.loading = false;
            console.log(err);
            await self.notificationService.warningSwal(
                this.errorTitle,
                'Am intampinat o problema in procesarea informatiilor dvs. Va rugam sa reincercati sau sa reveniti mai tarziu.',
                this.errorIcon
            );
        }
        return false;
    }

    clearInputFile(key: 'file_ci' | 'selfie_ci' | 'file_cui' | 'selfie_cui') {
        let obj: Record<string, any> = {};
        obj[key + '_source'] = '';
        this.formNewAccount.patchValue(obj);
        this.files[key] = [];
    }

    upload(event: any, key: 'file_ci' | 'selfie_ci' | 'file_cui' | 'selfie_cui') {
        const inputElement = event.target as HTMLInputElement;
        if (!inputElement.files) {
            return;
        }

        var self = this;
        const files = inputElement.files;
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file, file.name);

        this.uploading[key] = true;
        self.files[key] = [];

        return this.authService.fileUpload(formData).subscribe({
            next: (result: any) => {
                let response =
                    typeof result.status_code !== 'undefined' ? result : result.error;
                if (typeof response.status_code !== 'undefined') {
                    if (
                        response.status_code == 200 &&
                        typeof response.data !== 'undefined'
                    ) {
                        // make review and the amount to pay
                        self.files[key].push(result.data);

                        const control = this.formNewAccount.get(key + '_source');
                        if (control) {
                            control.setValue(JSON.stringify(this.files[key]));
                        }

                        self.uploading[key] = false;

                        self.openToast({
                            title: self.successTitle,
                            message: 'Fisierul dvs a fost adaugat cu succes.',
                            type: self.successIcon,
                        });
                    }
                } else {
                    // add sentry
                    let errorMessage =
                        environment.config.customNotifications.generalMessages.fileErrorMsg;
                    self.notificationService.warningSwal(
                        self.errorTitle,
                        errorMessage,
                        self.errorIcon
                    );
                    this.loading = false;
                    self.uploading[key] = false;
                }
            },
            error: (result: any) => {
                this.loading = false;
                self.uploading[key] = false;
                this.onHttpError(result.error);
            },
        });
    }

    uploadOcr(event: any, key: 'file_ci' | 'file_cui') {
        const inputElement = event.target as HTMLInputElement;
        if (!inputElement.files) {
            return;
        }

        const files = inputElement.files;
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file, file.name);

        this.uploading[key] = true;
        this.files[key] = [];

        return this.authService.fileUpload(formData).subscribe({
            next: (result: any) => {
                let response =
                    typeof result.status_code !== 'undefined' ? result : result.error;
                if (typeof response.status_code !== 'undefined') {
                    if (
                        response.status_code == 200 &&
                        typeof response.data !== 'undefined'
                    ) {
                        this.files[key].push(result.data);

                        const control = this.formNewAccount.get(key + '_source');
                        if (control) {
                            control.setValue(JSON.stringify(this.files[key]));
                        }

                        this.openToast({
                            title: this.successTitle,
                            message:
                                'Fisierul dvs a fost incarcat cu succes. Asteptati OCR-izarea...',
                            type: this.successIcon,
                        });

                        this.authService.processOcr(result.data.filename, key).subscribe({
                            next: async (result: any) => {
                                this.loading = false;
                                this.uploading[key] = false;

                                let response =
                                    typeof result.status_code !== 'undefined'
                                        ? result
                                        : result.error;
                                if (typeof response.status_code !== 'undefined') {
                                    if (
                                        response.status_code == 200 &&
                                        typeof response.data !== 'undefined'
                                    ) {
                                        //TODO: validations
                                        //if(result.data.cnp != this.cnpNewAccount() CNP diferit. ati incarcat buletinul altei persoane
                                        //if result.data.validity.endDate < now CI este expirat

                                        this.formNewAccount.patchValue({
                                            nume: result.data.name,
                                            prenume: result.data.lastName,
                                            cnp: result.data.cnp.toString(),
                                        });
                                        this.stepZone = 2;

                                        this.openToast({
                                            title: this.successTitle,
                                            message: 'Fisierul dvs a fost OCR-izat cu succes.',
                                            type: this.successIcon,
                                        });
                                    }
                                } else {
                                    // add sentry
                                    let errorMessage =
                                        environment.config.customNotifications.generalMessages
                                            .fileErrorMsg;
                                    await this.notificationService
                                        .warningSwal(
                                            this.errorTitle,
                                            'Documentul dumneavoastră nu a putut fi OCR-izat. Continuati cu inregistrarea manuala sau vreti sa incercati alt document ?',
                                            this.errorIcon,
                                            'Continua manual',
                                            'Reincearca',
                                            true
                                        )
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                this.formNewAccount.patchValue({
                                                    cnp: this.formCheck.value.cnp_cui,
                                                });
                                                this.stepZone = 2;
                                            } else {
                                                this.clearInputFile(key);
                                            }
                                        });
                                }
                            },
                            error: async (result: any) => {
                                this.loading = false;
                                this.uploading[key] = false;
                                await this.notificationService
                                    .warningSwal(
                                        this.errorTitle,
                                        'Documentul dumneavoastră nu a putut fi OCR-izat. Continuati cu inregistrarea manuala sau vreti sa incercati alt document ?',
                                        this.errorIcon,
                                        'Continua manual',
                                        'Reincearca',
                                        true
                                    )
                                    .then((result) => {
                                        if (result.isConfirmed) {
                                            this.formNewAccount.patchValue({
                                                cnp: this.formCheck.value.cnp_cui,
                                            });
                                            this.stepZone = 2;
                                        } else {
                                            this.clearInputFile(key);
                                        }
                                    });
                            },
                        });
                    }
                } else {
                    // add sentry
                    let errorMessage =
                        environment.config.customNotifications.generalMessages.fileErrorMsg;
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        'Fisierul nu a putut fi incarcat. Continuati catre inregistrarea manuala.',
                        this.errorIcon
                    );
                    this.loading = false;
                    this.uploading[key] = false;
                    this.stepZone = 2;
                }
            },
            error: (result: any) => {
                this.loading = false;
                this.uploading[key] = false;
                this.stepZone = 2;
                this.notificationService.warningSwal(
                    this.errorTitle,
                    'Fisierul nu a putut fi incarcat. Continuati catre inregistrarea manuala.',
                    this.errorIcon
                );
                //this.onHttpError(result.error);
            },
        });
    }

    resetPINCodeSuport() {
        let configData = Object.assign({}, this.dialogService.getDialogConfig(), {
            data: {
                cnp_cui: this.formCheck.value.cnp_cui,
            },
        });

        const dialogRef = this.dialog.open(ResetPinCodeComponent, configData);

        dialogRef.afterClosed().subscribe((res: any) => {
            const response =
                typeof res.data !== 'undefined' &&
                    res.data !== null &&
                    typeof res.data.id !== 'undefined'
                    ? res.data
                    : null;

            if (response !== null) {
                // show success stuff and reload data
                let message =
                    'Solicitarea dvs cu numărul ' +
                    response.reg_num +
                    '/' +
                    response.date +
                    ' a fost trimisă cu succes. ';
                this.notificationService.warningSwal(
                    this.successTitle,
                    message,
                    this.successIcon
                );
            }
        });
    }

    openToast(object: any) {
        const { type, message, title } = object;

        let options = this.toastr.toastrConfig;
        options.progressBar = true;
        options.preventDuplicates = true;
        options.closeButton = true;

        const inserted = this.toastr.show(
            message,
            title,
            options,
            options.iconClasses[type]
        );
    }

    onHttpError(res: any, showErrorsInToast: boolean = false) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                if (showErrorsInToast) {
                    this.openToast({
                        title: this.errorTitle,
                        message: msg,
                        type: this.errorType,
                    });
                } else {
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                }
            });
        } else {
            let errorMessage =
                environment.config.customNotifications.generalMessages.error;
            this.notificationService.warningSwal(
                this.errorTitle,
                errorMessage,
                this.errorIcon
            );
        }
    }

    navigateToLogin() {
        this.router.navigateByUrl(environment.config.loginUrl, {
            replaceUrl: true,
        });
    }

    openTermsModal(slug: string): void {
        let configData = Object.assign({}, this.dialogService.getDialogConfig(), {
            data: {
                slug: slug,
            },
        });
        const dialogRef = this.dialog.open(
            TermeniConditiiModalComponent,
            configData
        );
    }

    openConsimtamantModal(): void {
        let configData = Object.assign({}, this.dialogService.getDialogConfig(), {
            data: {},
        });
        const dialogRef = this.dialog.open(
            ConsimtamantModalComponent,
            configData
        );
    }
}
