<mat-card class="register-card">
    <!-- Header -->
    <mat-card-header class="register-card__header">
        <mat-card-title class="register-card__title">
            Confirmare cont preînrolat /
            <br /> Înregistrare cont nou
        </mat-card-title>
    </mat-card-header>

    <!-- Content -->
    <div *ngIf="stepZone == 0">
        <mat-card-content class="register-card__content">
            <!-- check CNP|CUI -->
            <!-- add pin form -->
            <form [formGroup]="formCheck" (ngSubmit)="checkStatusCNP()" *ngIf="stepZone == 0">
                <!-- cnp / cui -->
                <mat-form-field appearance="fill">
                    <mat-label>CNP sau CUI</mat-label>
                    <input matInput formControlName="cnp_cui" />
                    <mat-hint>Introduceți CNP-ul sau CUI-ul</mat-hint>
                    <mat-error *ngIf="cnpStatus && (cnpStatus.dirty || cnpStatus.touched) && cnpStatus.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error *ngIf="cnpStatus && (cnpStatus.dirty || cnpStatus.touched) && cnpStatus.hasError('minlength')">
                        Minim 4 caractere
                    </mat-error>
                    <mat-error *ngIf="cnpStatus && (cnpStatus.dirty || cnpStatus.touched) && cnpStatus.hasError('maxlength')">
                        Max 13 caractere
                    </mat-error>
                </mat-form-field>
            </form>
        </mat-card-content>

        <!-- Divider -->
        <mat-divider class="divider"></mat-divider>

        <!-- Footer -->
        <mat-card-content class="register-card__footer">
            <button (click)="checkStatusCNP()" mat-stroked-button [disabled]="!formCheck.valid" [class.register-card-button-disabled]="!formCheck.valid"
                class="register-card-button">
                Verificați
            </button>

            <div class="register-card__footer-message">
                Aveți deja cont?
            </div>
            <button routerLink="/pages/login" class="register-card-button register-card-button-two" mat-stroked-button color="primary">
                autentificați-vă aici
            </button>
            <button class="register-card-button register-card-button-two" mat-stroked-button color="primary" (click)="openDialogSuportOnline('INREGISTRARE_CONT_PAGE')">
                ÎNTREBĂRI FRECVENTE / SUPORT TEHNIC
            </button>
        </mat-card-content>
    </div>

    <!-- verify cont preinrolat -->
    <div class="row" *ngIf="stepZone == 1">
        <!-- add pin form -->
        <div *ngIf="switchForm == 0">
            <mat-card-content class="register-card__content">
                <form [formGroup]="formPIN" (ngSubmit)="checkPIN()">

                    <!-- cnp / cui -->
                    <mat-form-field class="example-full-width mt-2 mb-2">
                        <input formControlName="cnp_cui" matInput placeholder="CNP/CUI" [errorStateMatcher]="matcher" required="required">

                        <mat-error *ngIf="cnpPIN && (cnpPIN.dirty || cnpPIN.touched) && cnpPIN.hasError('required')">
                            Câmp obligatoriu
                        </mat-error>
                        <mat-error *ngIf="cnpPIN && (cnpPIN.dirty || cnpPIN.touched) && cnpPIN.hasError('minlength')">
                            Minim 4 caractere
                        </mat-error>

                        <mat-error *ngIf="cnpPIN && (cnpPIN.dirty || cnpPIN.touched) && cnpPIN.hasError('maxlength')">
                            Max 13 caractere
                        </mat-error>
                    </mat-form-field>

                    <!-- pin code -->
                    <mat-form-field class="example-full-width mt-2 mb-4">
                        <mat-label>COD UNIC</mat-label>
                        <input formControlName="pin" matInput [errorStateMatcher]="matcher" type="text" required="required">
                        <mat-hint>Introduceți codul unic primit pe email DGUAT/notificare DGITL S4</mat-hint>
                        <mat-error *ngIf="pin && (pin.dirty || pin.touched) && pin.hasError('required')">
                            Câmp obligatoriu
                        </mat-error>
                        <mat-error *ngIf="pin && (pin.dirty || pin.touched) && pin.hasError('minlength')">
                            Minim 8 caractere
                        </mat-error>

                        <mat-error *ngIf="pin && (pin.dirty || pin.touched) && pin.hasError('maxlength')">
                            Max 8 caractere
                        </mat-error>
                    </mat-form-field>
                </form>
            </mat-card-content>

            <!-- Footer -->
            <mat-card-content class="register-card__footer">
                <button (click)="checkPIN()" mat-flat-button color="primary" [disabled]="!formPIN.valid" class="register-card-button">
                    Verificați
                </button>
                <div class="divider"></div>
                <button mat-stroked-button (click)="resetPINCodeSuport()" color="primary" class="register-card-button register-card-button-two">
                    RECUPERARE PAROLĂ NOTIFICARE | COD UNIC
                </button>

                <div class="divider"></div>
                <div class="register-card__footer-message">
                    Aveți deja cont?
                </div>
                <button routerLink="/pages/login" class="register-card-button register-card-button-two" mat-stroked-button color="primary">
                    autentificați-vă aici
                </button>
                <button class="register-card-button register-card-button-two" mat-stroked-button color="primary" (click)="openDialogSuportOnline('CONFIRMARE_CONT_PREINROLAT_PAGE')">
                    ÎNTREBĂRI FRECVENTE / SUPORT TEHNIC
                </button>
            </mat-card-content>
        </div>

        <div *ngIf="switchForm == 1">
            <!-- add form -->
            <mat-card-content class="register-card__content">
                <form [formGroup]="form" (ngSubmit)="store()">
                    <!-- nume -->
                    <mat-form-field appearance="fill">
                        <mat-label>Nume</mat-label>
                        <input formControlName="nume" matInput [errorStateMatcher]="matcher" type="text" required="required">
                        <mat-hint>Introduceți numele de familie</mat-hint>

                        <mat-error *ngIf="nume && (nume.dirty || nume.touched) && nume.hasError('required')">
                            Câmp obligatoriu
                        </mat-error>
                        <mat-error *ngIf="nume && (nume.dirty || nume.touched) && nume.hasError('minlength')">
                            Minim {{env.config.validatorsAccrossApp.minStringLength}} caractere
                        </mat-error>
                        <mat-error *ngIf="nume && (nume.dirty || nume.touched) && nume.hasError('maxlength')">
                            Max {{env.config.validatorsAccrossApp.maxStringLength}} caractere
                        </mat-error>
                    </mat-form-field>

                    <!-- prenume -->
                    <mat-form-field appearance="fill">
                        <mat-label>Prenume</mat-label>
                        <input formControlName="prenume" matInput [errorStateMatcher]="matcher" type="text" required="required">
                        <mat-hint>Introduceți prenumele</mat-hint>

                        <mat-error *ngIf="prenume && (prenume.dirty ||prenume.touched) && prenume.hasError('required')">
                            Câmp obligatoriu
                        </mat-error>
                        <mat-error *ngIf="prenume && (prenume.dirty || prenume.touched) && prenume.hasError('minlength')">
                            Minim {{env.config.validatorsAccrossApp.minStringLength}} caractere
                        </mat-error>

                        <mat-error *ngIf="prenume && (prenume.dirty || prenume.touched) && prenume.hasError('maxlength')">
                            Max {{env.config.validatorsAccrossApp.maxStringLength}} caractere
                        </mat-error>
                    </mat-form-field>

                    <!-- cnp / cui -->
                    <mat-form-field appearance="fill">
                        <input formControlName="cnp" matInput placeholder="CNP/CUI" [errorStateMatcher]="matcher" required="required">
                        <mat-hint>Introduceți CNP-ul</mat-hint>

                        <mat-error *ngIf="cnp && (cnp.dirty || cnp.touched) && cnp.hasError('required')">
                            Câmp obligatoriu
                        </mat-error>
                        <mat-error *ngIf="cnp && (cnp.dirty || cnp.touched) && cnp.hasError('minlength')">
                            Minim 4 caractere
                        </mat-error>
                        <mat-error *ngIf="cnp && (cnp.dirty || cnp.touched) && cnp.hasError('maxlength')">
                            Max 13 caractere
                        </mat-error>
                    </mat-form-field>

                    <!-- denumire firma -->
                    <mat-form-field appearance="fill" *ngIf="form.value.cnp !== null && form.value.cnp.toString().length < 13 && form.value.cnp.toString().length > 2">
                        <mat-label>Numele companiei</mat-label>
                        <input formControlName="f_nume" matInput [errorStateMatcher]="matcher" type="text">
                        <mat-hint>Introduceți numele companiei</mat-hint>
                    </mat-form-field>

                    <!-- telefon -->
                    <mat-form-field appearance="fill">
                        <button class="country-selector" mat-button matPrefix [matMenuTriggerFor]="menu">
                            <mat-icon>expand_more</mat-icon>
                            {{ currentOption.flag }} {{ currentOption.dial_code }}
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="changeCountry(optionRom)">
                                <span class="flag-wrapper">🇷🇴</span>
                                <span class="label-wrapper">
                                    {{ optionRom.name }} {{ optionRom.dial_code }}
                                </span>
                            </button>
                            <mat-divider class="divider"></mat-divider>
                            <button mat-menu-item *ngFor="let option of options" (click)="changeCountry(option)">
                                <span class="flag-wrapper">{{ option.flag }}</span>
                                <span class="label-wrapper">
                                    {{ option.name }} {{ option.dial_code }}
                                </span>
                            </button>
                        </mat-menu>
                        <input type="text" matInput formControlName="telefon" />
                        <mat-hint>Introduceți numărul de telefon</mat-hint>
                        <mat-error *ngIf="!form.controls['telefon'].valid && form.controls['telefon'].touched">
                            Câmp obligatoriu
                        </mat-error>
                    </mat-form-field>

                    <!-- email -->
                    <mat-form-field appearance="fill">
                        <mat-label>Email</mat-label>
                        <input formControlName="email" matInput [errorStateMatcher]="matcher" type="email">
                        <mat-hint>Introduceți email-ul</mat-hint>
                        <mat-error *ngIf="email && (email.dirty || email.touched) && email.hasError('required')">
                            Câmp obligatoriu
                        </mat-error>
                    </mat-form-field>

                    <!-- parole -->
                    <mat-form-field appearance="fill">
                        <input formControlName="password" matInput placeholder="Parola noua" [errorStateMatcher]="matcher" required="required" [type]="hide ? 'password' : 'text'">
                        <mat-hint>Introduceți parola</mat-hint>

                        <mat-icon matSuffix (click)="hide = !hide">
                            {{hide ? 'visibility_off' : 'visibility'}}
                        </mat-icon>

                        <mat-error *ngIf="password && (password.dirty || password.touched) && password.hasError('required')">
                            Câmp obligatoriu
                        </mat-error>
                        <mat-error *ngIf="password && (password.dirty || password.touched) && password.hasError('minlength')">
                            Minim {{env.config.validatorsAccrossApp.minPassword}} caractere
                        </mat-error>
                        <mat-error *ngIf="password && (password.dirty || password.touched) && password.hasError('maxlength')">
                            Max {{env.config.validatorsAccrossApp.maxPassword}} caractere
                        </mat-error>
                        <mat-error *ngIf="password && (password.dirty || password.touched) && password.hasError('noSpecialChar')">
                            Minim 1 caracter special
                        </mat-error>
                        <mat-error *ngIf="password && (password.dirty || password.touched) && password.hasError('noNumber')">
                            Minim 1 cifră
                        </mat-error>
                        <mat-error *ngIf="password && (password.dirty || password.touched) && password.hasError('noUpperCase')">
                            Minim 1 literă mare
                        </mat-error>

                    </mat-form-field>

                    <!-- parole_confirm -->
                    <mat-form-field appearance="fill">
                        <input formControlName="password_confirmation" matInput placeholder="Confirmați parola noua" [errorStateMatcher]="matcher"
                            required="required" [type]="hide_confirmation ? 'password' : 'text'">
                        <mat-hint>Reintroduceți parola</mat-hint>

                        <mat-icon matSuffix (click)="hide_confirmation = !hide_confirmation">
                            {{hide_confirmation ? 'visibility_off' : 'visibility'}}
                        </mat-icon>

                        <mat-error *ngIf="passwordConfirmation && (passwordConfirmation.dirty || passwordConfirmation.touched) && passwordConfirmation.hasError('required')">
                            Câmp obligatoriu
                        </mat-error>
                        <mat-error
                            *ngIf="passwordConfirmation && (passwordConfirmation.dirty || passwordConfirmation.touched) && passwordConfirmation.hasError('mismatch')">
                            Parolele nu se potrivesc
                        </mat-error>
                    
                    </mat-form-field>

                    <div class="password-conditions-box" style="margin: 20px 0 0 15px;">
                        <p>Parola trebuie să conțină:
                            <br /> &#8226; Minim {{env.config.validatorsAccrossApp.minPassword}} caractere
                            <br /> &#8226; Minim o literă mare
                            <br /> &#8226; Minim o literă mică
                            <br /> &#8226; Minim un caracter special
                            <br /> &#8226; Minim o cifră
                        </p>
                    </div>

                    <mat-checkbox formControlName="declar_propria_rasp" color="primary">
                        Declar în deplină cunoștință și pe proprie răspundere, cunoscând dispozițiile art. 326 Cod Penal referitoare la falsul în
                        declarații
                    </mat-checkbox>

                    <mat-checkbox formControlName="declar_acte" color="primary">
                        Declar că sunt de acord cu transmiterea actelor administrative pe cale electronică
                    </mat-checkbox>

                    <mat-checkbox formControlName="termeni_conditii" color="primary">
                        Sunt de acord cu
                        <a (click)="openTermsModal('termeni-si-conditii')">
                            Termenii și Condițiile de Confidențialitate ale Platformei online a Primăriei Sectorului 4
                        </a>
                    </mat-checkbox>

                    <mat-checkbox formControlName="consimtamant" color="primary">
                        <a (click)="openConsimtamantModal()">
                            Consimțământ pentru utilizarea datelor cu caracter personal
                        </a>
                    </mat-checkbox>

                    <div class="divider"></div>

                    <div class="register-card__info">
                        <div class="info">
                            <mat-icon class="info-icon">info</mat-icon>* Câmpuri obligatorii
                        </div>
                        <div class="info">
                            <div>
                                <mat-icon class="info-icon">info</mat-icon>
                            </div>
                            <div>Confirmarea contului se va face prin SMS către numărul de telefon menționat în formularul de
                                mai sus</div>
                        </div>
                    </div>
                </form>
            </mat-card-content>

            <mat-divider class="divider"></mat-divider>

            <mat-card-content class="register-card__footer">
                <button (click)="store()" mat-stroked-button [disabled]="!form.valid" [class.register-card-button-disabled]="!form.valid"
                    class="register-card-button">
                    ÎNREGISTRARE CONT
                </button>
                <button class="register-card-button register-card-button-two" mat-stroked-button color="primary" (click)="openDialogSuportOnline('CONFIRMARE_CONT_PREINROLAT_PAGE')">
                    ÎNTREBĂRI FRECVENTE / SUPORT TEHNIC
                </button>
            </mat-card-content>
        </div>
    </div>
    <!-- end stepzone 1, cont preinrolat -->

    <!-- add stepzone 2, cont nou -->
    <div class="row" *ngIf="stepZone == 2">
        <mat-card-content class="register-card__content">

            <!-- add form -->
            <form [formGroup]="formNewAccount" (ngSubmit)="storeNewAccount()">

                <!-- nume -->
                <mat-form-field appearance="fill">
                    <mat-label>Nume</mat-label>
                    <input formControlName="nume" matInput placeholder="Nume" [errorStateMatcher]="matcher" type="text" required="required">
                    <mat-hint>Introduceți numele de familie</mat-hint>

                    <mat-error *ngIf="numeNewAccount && (numeNewAccount.dirty || numeNewAccount.touched) && numeNewAccount.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error *ngIf="numeNewAccount && (numeNewAccount.dirty || numeNewAccount.touched) && numeNewAccount.hasError('minlength')">
                        Minim {{env.config.validatorsAccrossApp.minStringLength}} caractere
                    </mat-error>
                    <mat-error *ngIf="numeNewAccount && (numeNewAccount.dirty || numeNewAccount.touched) && numeNewAccount.hasError('maxlength')">
                        Max {{env.config.validatorsAccrossApp.maxStringLength}} caractere
                    </mat-error>
                </mat-form-field>

                <!-- prenume -->
                <mat-form-field appearance="fill">
                    <mat-label>Prenume</mat-label>
                    <input formControlName="prenume" matInput [errorStateMatcher]="matcher" type="text" required="required">
                    <mat-hint>Introduceți prenumele</mat-hint>

                    <mat-error *ngIf="prenumeNewAccount && (prenumeNewAccount.dirty || prenumeNewAccount.touched) && prenumeNewAccount.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error *ngIf="prenumeNewAccount && (prenumeNewAccount.dirty || prenumeNewAccount.touched) && prenumeNewAccount.hasError('minlength')">
                        Minim {{env.config.validatorsAccrossApp.minStringLength}} caractere
                    </mat-error>
                    <mat-error *ngIf="prenumeNewAccount && (prenumeNewAccount.dirty || prenumeNewAccount.touched) && prenumeNewAccount.hasError('maxlength')">
                        Max {{env.config.validatorsAccrossApp.maxStringLength}} caractere
                    </mat-error>
                </mat-form-field>

                <!-- cnp / cui -->
                <mat-form-field appearance="fill">
                    <input formControlName="cnp" matInput placeholder="CNP/CUI" [errorStateMatcher]="matcher" required="required">

                    <mat-error *ngIf="cnpNewAccount && (cnpNewAccount.dirty || cnpNewAccount.touched) && cnpNewAccount.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error *ngIf="cnpNewAccount && (cnpNewAccount.dirty || cnpNewAccount.touched) && cnpNewAccount.hasError('minlength')">
                        Minim 3 caractere
                    </mat-error>
                    <mat-error *ngIf="cnpNewAccount && (cnpNewAccount.dirty || cnpNewAccount.touched) && cnpNewAccount.hasError('maxlength')">
                        Max 13 caractere
                    </mat-error>
                </mat-form-field>

                <!-- denumire firma -->
                <mat-form-field appearance="fill" *ngIf="formNewAccount.value.cnp !== null && formNewAccount.value.cnp.toString().length < 13 && formNewAccount.value.cnp.toString().length > 2">
                    <mat-label>Numele companiei</mat-label>
                    <input formControlName="f_nume" matInput [errorStateMatcher]="matcher" type="text">
                    <mat-hint>Introduceți numele companiei</mat-hint>
                </mat-form-field>

                <!-- telefon -->
                <mat-form-field appearance="fill">
                    <button type="button" class="country-selector " mat-button matPrefix [matMenuTriggerFor]="menu">
                        <mat-icon>expand_more</mat-icon>
                        {{ currentOption.flag }} {{ currentOption.dial_code }}
                    </button>
                    <mat-menu #menu="matMenu">
                        <button type="button" mat-menu-item (click)="changeCountry(optionRom)">
                            <span class="flag-wrapper">🇷🇴</span>
                            <span class="label-wrapper">
                                {{ optionRom.name }} {{ optionRom.dial_code }}
                            </span>
                        </button>
                        <mat-divider class="divider"></mat-divider>
                        <button type="button" mat-menu-item *ngFor="let option of options" (click)="changeCountry(option)">
                            <span class="flag-wrapper">{{ option.flag }}</span>
                            <span class="label-wrapper">
                                {{ option.name }} {{ option.dial_code }}
                            </span>
                        </button>
                    </mat-menu>
                    <input type="text" matInput formControlName="telefon" />
                    <mat-hint>Introduceți numărul de telefon</mat-hint>
                    <mat-error *ngIf="!formNewAccount.controls['telefon'].valid && formNewAccount.controls['telefon'].touched">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>

                <!-- email -->
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input formControlName="email" matInput [errorStateMatcher]="matcher" type="email" required="required">
                    <mat-hint>Introduceți email-ul</mat-hint>

                    <mat-error *ngIf="emailNewAccount && (emailNewAccount.dirty || emailNewAccount.touched) && emailNewAccount.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error *ngIf="emailNewAccount && (emailNewAccount.dirty || emailNewAccount.touched) && (emailNewAccount.hasError('email') || emailNewAccount.hasError('pattern'))">
                        Email invalid
                    </mat-error>
                </mat-form-field>

                <!-- parole -->
                <mat-form-field  appearance="fill">
                    <mat-label>Parola</mat-label>
                    <input formControlName="password" matInput [errorStateMatcher]="matcher" required="required" [type]="hide ? 'password' : 'text'" class="mb-25">
                    <mat-hint>Introduceți parola</mat-hint>
                    <mat-icon matSuffix (click)="hide = !hide">
                        {{hide ? 'visibility_off' : 'visibility'}}
                    </mat-icon>

                    <mat-error *ngIf="passwordNewAccount && (passwordNewAccount.dirty || passwordNewAccount.touched) && passwordNewAccount.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error *ngIf="passwordNewAccount && (passwordNewAccount.dirty || passwordNewAccount.touched) && passwordNewAccount.hasError('minlength')">
                        Minim {{env.config.validatorsAccrossApp.minPassword}} caractere
                    </mat-error>
                    <mat-error *ngIf="passwordNewAccount && (passwordNewAccount.dirty || passwordNewAccount.touched) && passwordNewAccount.hasError('maxlength')">
                        Max {{env.config.validatorsAccrossApp.maxPassword}} caractere
                    </mat-error>
                    <mat-error
                        *ngIf="passwordNewAccount && (passwordNewAccount.dirty || passwordNewAccount.touched) && passwordNewAccount.hasError('noSpecialChar')">
                        Minim 1 caracter special
                    </mat-error>
                    <mat-error
                        *ngIf="passwordNewAccount && (passwordNewAccount.dirty || passwordNewAccount.touched) && passwordNewAccount.hasError('noNumber')">
                        Minim 1 cifră
                    </mat-error>
                    <mat-error *ngIf="passwordNewAccount && (passwordNewAccount.dirty || passwordNewAccount.touched) && passwordNewAccount.hasError('noUpperCase')">
                        Minim 1 literă mare
                      </mat-error>
                </mat-form-field>

                <!-- parole_confirm -->
                <mat-form-field appearance="fill">
                    <mat-label>Confirmați parola</mat-label>
                    <input formControlName="password_confirmation" matInput [errorStateMatcher]="matcher" required="required" [type]="hide_confirmation ? 'password' : 'text'">
                    <mat-hint>Reintroduceți parola</mat-hint>
                    <mat-icon matSuffix (click)="hide_confirmation = !hide_confirmation">
                        {{hide_confirmation ? 'visibility_off' : 'visibility'}}
                    </mat-icon>

                <mat-error
                    *ngIf="passwordConfirmationNewAccount && (passwordConfirmationNewAccount.dirty || passwordConfirmationNewAccount.touched) && passwordConfirmationNewAccount.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error
                        *ngIf="passwordConfirmationNewAccount && (passwordConfirmationNewAccount.dirty || passwordConfirmationNewAccount.touched) && passwordConfirmationNewAccount.hasError('mismatch')">
                        Parolele nu se potrivesc
                    </mat-error>
                 
                </mat-form-field>

                <div class="password-conditions-box" style="margin-top: 15px;">
					<p>Parola
                        trebuie să conțină:
						<br /> &#8226; Minim {{env.config.validatorsAccrossApp.minPassword}} caractere
						<br /> &#8226; Minim o literă mare
						<br /> &#8226; Minim o literă mică
						<br /> &#8226; Minim un caracter special
						<br /> &#8226; Minim o cifră
					</p>
				</div>


                <!-- add files here -->
                <mat-card-title class="register-card__title">
                    Încărcare Fișiere / Documente obligatorii
                </mat-card-title>

                <!-- file wrapper -->
                <!-- file_ci wrapper -->
                <div class="register-card__upload-file">
                    <mat-label>
                        <span>Carte de Identitate</span>
                    </mat-label>
                    <p>Încărcați cartea dumneavoastră de identitate</p>
                    <button class="register-card__upload-container-buttons-upload" type="button" *ngIf="formNewAccount.value.file_ci_source === '' && !uploading.file_ci"
                        (click)="fileCiInput.click()">
                        <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                        <span>ÎNCARCĂ</span>
                    </button>
                    <div class="register-card__upload-container" *ngIf="formNewAccount.value.file_ci_source !== '' && !uploading.file_ci">
                        <span *ngIf="formNewAccount.value.file_ci_source !== '' && files.file_ci.length > 0" class="register-card__upload-container-name">
                            <span>
                                {{ (files.file_ci[0].original_name.length > 15)? (files.file_ci[0].original_name | slice:0:15)+'..':(files.file_ci[0].original_name)
                                }}
                            </span>
                        </span>
                        <div class="register-card__upload-container-buttons">
                            <button class="register-card__upload-container-buttons-upload register-card__upload-container-buttons-upload2" type="button"
                                (click)="fileCiInput.click()">
                                <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                                <span>REÎNCARCĂ</span>
                            </button>
                            <button class="register-card__upload-container-buttons-delete" type="button" matTooltip="Șterge fișierul încărcat" (click)="fileCiInput.value = ''; clearInputFile('file_ci')">
                                <img class="btn-img" src="assets/icons/delete.svg" alt="" />
                            </button>
                        </div>
                    </div>
                    <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.file_ci"></mat-spinner>

                    <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileCiInput
                        name="fileCiInput" (change)="upload($event, 'file_ci')" style="display: none">
                </div>

                <!-- selfie_ci wrapper -->
                <div class="register-card__upload-file">
                    <mat-label>
                        <span>Selfie cu CI-ul dvs</span>
                    </mat-label>
                    <p>Încărcați o poză cu cartea dumneavoastră de identitate apropiată de fața dumneavoastră</p>
                    <button class="register-card__upload-container-buttons-upload" type="button" *ngIf="formNewAccount.value.selfie_ci_source === '' && !uploading.selfie_ci"
                        (click)="fileSelfieCiInput.click()">
                        <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                        <span>ÎNCARCĂ</span>
                    </button>
                    <div class="register-card__upload-container" *ngIf="formNewAccount.value.selfie_ci_source !== '' && !uploading.selfie_ci">
                        <span *ngIf="formNewAccount.value.selfie_ci_source !== '' && files.selfie_ci.length > 0" class="register-card__upload-container-name">
                            <span>
                                {{ (files.selfie_ci[0].original_name.length > 15)? (files.selfie_ci[0].original_name | slice:0:15)+'..':(files.selfie_ci[0].original_name)
                                }}
                            </span>
                        </span>
                        <div class="register-card__upload-container-buttons">
                            <button class="register-card__upload-container-buttons-upload register-card__upload-container-buttons-upload2" type="button"
                                (click)="fileSelfieCiInput.click()">
                                <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                                <span>REÎNCARCĂ</span>
                            </button>
                            <button class="register-card__upload-container-buttons-delete" type="button" matTooltip="Șterge fișierul încărcat" (click)="fileSelfieCiInput.value = ''; clearInputFile('selfie_ci')">
                                <img class="btn-img" src="assets/icons/delete.svg" alt="" />
                            </button>
                        </div>
                    </div>
                    <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.selfie_ci"></mat-spinner>

                    <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileSelfieCiInput
                        name="fileSelfieCiInput" (change)="upload($event, 'selfie_ci')" style="display: none">
                </div>

                <!-- file_cui wrapper -->
                <div class="register-card__upload-file" *ngIf="formNewAccount.value.cnp !== null && formNewAccount.value.cnp.toString().length < 13 && formNewAccount.value.cnp.toString().length > 2">
                    <mat-label>
                        <span>CERTIFICAT ÎNREGISTRARE SOCIETATE</span>
                    </mat-label>
                    <p>Încărcați o poză cu certificatul de înregistrare al societății dumneavoastră</p>
                    <button class="register-card__upload-container-buttons-upload" type="button" *ngIf="formNewAccount.value.file_cui_source === '' && !uploading.file_cui"
                        (click)="fileCuiInput.click()">
                        <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                        <span>ÎNCARCĂ</span>
                    </button>
                    <div class="register-card__upload-container" *ngIf="formNewAccount.value.file_cui_source !== '' && !uploading.file_cui">
                        <span *ngIf="formNewAccount.value.file_cui_source !== '' && files.file_cui.length > 0" class="register-card__upload-container-name">
                            <span>
                                {{ (files.file_cui[0].original_name.length > 15)? (files.file_cui[0].original_name | slice:0:15)+'..':(files.file_cui[0].original_name)
                                }}
                            </span>
                        </span>
                        <div class="register-card__upload-container-buttons">
                            <button class="register-card__upload-container-buttons-upload register-card__upload-container-buttons-upload2" type="button"
                                (click)="fileCuiInput.click()">
                                <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                                <span>REÎNCARCĂ</span>
                            </button>
                            <button class="register-card__upload-container-buttons-delete" type="button" matTooltip="Șterge fișierul încărcat" (click)="fileCuiInput.value = ''; clearInputFile('file_cui')">
                                <img class="btn-img" src="assets/icons/delete.svg" alt="" />
                            </button>
                        </div>
                    </div>
                    <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.file_cui"></mat-spinner>

                    <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileCuiInput
                        name="fileCuiInput" (change)="upload($event, 'file_cui')" style="display: none">
                </div>

                <div class="register-card__checkbox">

                    <mat-checkbox formControlName="declar_propria_rasp" color="primary">
                        Declar în deplină cunoștință și pe proprie răspundere, cunoscând dispozițiile art. 326 Cod Penal referitoare la falsul în
                        declarații
                    </mat-checkbox>
                    <br />
                    <mat-checkbox formControlName="declar_acte" color="primary">
                        Declar că sunt de acord cu transmiterea actelor administrative pe cale electronică
                    </mat-checkbox>
                    <br />
                    <mat-checkbox formControlName="termeni_conditii" color="primary">
                        Sunt de acord cu
                        <a (click)="openTermsModal('termeni-si-conditii')">
                            Termenii și Condițiile de Confidențialitate ale Platformei online a Primăriei Sectorului 4
                        </a>
                    </mat-checkbox>
                    <br />
                    <mat-checkbox formControlName="consimtamant" color="primary">
                        <a (click)="openConsimtamantModal()">
                            Consimțământ pentru utilizarea datelor cu caracter personal
                        </a>
                    </mat-checkbox>
                </div>

                <div class="divider"></div>

                <div class="register-card__info">
                    <div class="info">
                        <div>
                            <mat-icon class="info-icon">info</mat-icon>
                        </div>
                        <div>* Câmpuri obligatorii</div>
                    </div>
                    <div class="info">
                        <div>
                            <mat-icon class="info-icon">info</mat-icon>
                        </div>
                        <div>Confirmarea contului se va face prin SMS către numărul de telefon menționat în formularul de mai
                            sus
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>

        <mat-divider class="divider"></mat-divider>

        <mat-card-content class="register-card__footer">
            <button (click)="storeNewAccount()" class="register-card-button" mat-stroked-button [disabled]="!formNewAccount.valid" [class.register-card-button-disabled]="!formNewAccount.valid">
                ÎNREGISTRAți cont nou
            </button>
            <div class="register-card__footer-message">
                Aveți deja cont?
                <button routerLink="/pages/login" class="register-card-button register-card-button-two" mat-stroked-button color="primary">
                    autentificați-vă aici
                </button>
            </div>
            <button class="register-card-button register-card-button-two" mat-stroked-button color="primary" (click)="openDialogSuportOnline('INREGISTRARE_CONT_PAGE')">
                ÎNTREBĂRI FRECVENTE / SUPORT TEHNIC
            </button>
        </mat-card-content>
    </div>



    <!-- add stepzone 21, upload CI pentru ocr-izare -->
    <div class="row" *ngIf="stepZone == 21">
        <mat-card-content class="register-card__content">

            <!-- add form -->
            <form [formGroup]="formNewAccount">
                <!-- cnp / cui -->
                <mat-form-field appearance="fill">
                    <input formControlName="cnp" matInput placeholder="CNP/CUI" [errorStateMatcher]="matcher" required="required">

                    <mat-error *ngIf="cnpNewAccount && (cnpNewAccount.dirty || cnpNewAccount.touched) && cnpNewAccount.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error *ngIf="cnpNewAccount && (cnpNewAccount.dirty || cnpNewAccount.touched) && cnpNewAccount.hasError('minlength')">
                        Minim 4 caractere
                    </mat-error>
                    <mat-error *ngIf="cnpNewAccount && (cnpNewAccount.dirty || cnpNewAccount.touched) && cnpNewAccount.hasError('maxlength')">
                        Max 13 caractere
                    </mat-error>
                </mat-form-field>

                <!-- add files here -->
                <mat-card-title class="register-card__title">
                    Încărcare Fișiere / Documente obligatorii
                </mat-card-title>

                <!-- file wrapper -->
                <!-- file_ci wrapper -->
                <div class="register-card__upload-file" *ngIf="formNewAccount.value.cnp !== null && formNewAccount.value.cnp.toString().length == 13">
                    <mat-label>
                        <span>Carte de Identitate</span>
                    </mat-label>
                    <p>Încărcați cartea dumneavoastră de identitate</p>
                    <button class="register-card__upload-container-buttons-upload" type="button" *ngIf="formNewAccount.value.file_ci_source === '' && !uploading.file_ci"
                        (click)="fileCiInput.click()">
                        <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                        <span>ÎNCARCĂ</span>
                    </button>
                    <div class="register-card__upload-container" *ngIf="formNewAccount.value.file_ci_source !== '' && !uploading.file_ci">
                        <span *ngIf="formNewAccount.value.file_ci_source !== '' && files.file_ci.length > 0" class="register-card__upload-container-name">
                            <span>
                                {{ (files.file_ci[0].original_name.length > 15)? (files.file_ci[0].original_name | slice:0:15)+'..':(files.file_ci[0].original_name)
                                }}
                            </span>
                        </span>
                        <div class="register-card__upload-container-buttons">
                            <button class="register-card__upload-container-buttons-upload register-card__upload-container-buttons-upload2" type="button"
                                (click)="fileCiInput.click()">
                                <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                                <span>REÎNCARCĂ</span>
                            </button>
                            <button class="register-card__upload-container-buttons-delete" type="button" matTooltip="Șterge fișierul încărcat" (click)="fileCiInput.value = ''; clearInputFile('file_ci')">
                                <img class="btn-img" src="assets/icons/delete.svg" alt="" />
                            </button>
                        </div>
                    </div>
                    <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.file_ci"></mat-spinner>

                    <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileCiInput
                        name="fileCiInput" (change)="uploadOcr($event, 'file_ci')" style="display: none">
                </div>

                <!-- file_cui wrapper -->
                <div class="register-card__upload-file" *ngIf="formNewAccount.value.cnp !== null && formNewAccount.value.cnp.toString().length < 13 && formNewAccount.value.cnp.toString().length > 2">
                    <mat-label>
                        <span>CERTIFICAT ÎNREGISTRARE SOCIETATE</span>
                    </mat-label>
                    <p>Încărcați o poză cu certificatul de înregistrare al societății dumneavoastră</p>
                    <button class="register-card__upload-container-buttons-upload" type="button" *ngIf="formNewAccount.value.file_cui_source === '' && !uploading.file_cui"
                        (click)="fileCuiInput.click()">
                        <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                        <span>ÎNCARCĂ</span>
                    </button>
                    <div class="register-card__upload-container" *ngIf="formNewAccount.value.file_cui_source !== '' && !uploading.file_cui">
                        <span *ngIf="formNewAccount.value.file_cui_source !== '' && files.file_cui.length > 0" class="register-card__upload-container-name">
                            <span>
                                {{ (files.file_cui[0].original_name.length > 15)? (files.file_cui[0].original_name | slice:0:15)+'..':(files.file_cui[0].original_name)
                                }}
                            </span>
                        </span>
                        <div class="register-card__upload-container-buttons">
                            <button class="register-card__upload-container-buttons-upload register-card__upload-container-buttons-upload2" type="button"
                                (click)="fileCuiInput.click()">
                                <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                                <span>REÎNCARCĂ</span>
                            </button>
                            <button class="register-card__upload-container-buttons-delete" type="button" matTooltip="Șterge fișierul încărcat" (click)="fileCuiInput.value = ''; clearInputFile('file_cui')">
                                <img class="btn-img" src="assets/icons/delete.svg" alt="" />
                            </button>
                        </div>
                    </div>
                    <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.file_cui"></mat-spinner>

                    <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileCuiInput
                        name="fileCuiInput" (change)="uploadOcr($event, 'file_cui')" style="display: none">
                </div>

                <div class="divider"></div>

                <div class="register-card__info">
                    <div class="info">
                        <div>
                            <mat-icon class="info-icon">info</mat-icon>
                        </div>
                        <div>* Câmpuri obligatorii</div>
                    </div>
                </div>
            </form>
        </mat-card-content>

        <mat-divider class="divider"></mat-divider>

        <mat-card-content class="register-card__footer">
            <div class="register-card__footer-message">
                Aveți deja cont?
                <button routerLink="/pages/login" class="register-card-button register-card-button-two" mat-stroked-button color="primary">
                    autentificați-vă aici
                </button>
            </div>
            <button class="register-card-button register-card-button-two" mat-stroked-button color="primary" (click)="openDialogSuportOnline('INREGISTRARE_CONT_OCR')">
                ÎNTREBĂRI FRECVENTE / SUPORT TEHNIC
            </button>
        </mat-card-content>
    </div>









    <!-- confirm pin code/sms -->
    <div class="row" *ngIf="stepZone == 3">
        <!-- code form -->
        <mat-card-content class="register-card__content">
            <form [formGroup]="formCode" (ngSubmit)="confirmCode()">
                <mat-form-field class="example-full-width mt-2 mb-2">
                    <mat-label>Cod SMS</mat-label>
                    <input matInput formControlName="code" />
                    <mat-hint>Introduceți codul primit prin SMS la numarul de telefon {{phone_no}}</mat-hint>
                    <mat-error *ngIf="codeInput && (codeInput?.dirty || codeInput?.touched) && codeInput?.hasError('required')">
                        Câmp obligatoriu
                    </mat-error>
                    <mat-error *ngIf="codeInput && (codeInput?.dirty || codeInput?.touched) && codeInput?.hasError('minlength')">
                        Codul trebuie să conțină 5 cifre
                    </mat-error>
                    <mat-error *ngIf="codeInput && (codeInput?.dirty || codeInput?.touched) && codeInput?.hasError('maxlength')">
                        Codul trebuie să conțină 5 cifre
                    </mat-error>
                </mat-form-field>
            </form>
        </mat-card-content>
        <mat-card-content class="register-card__footer">
            <button (click)="confirmCode()" [disabled]="!formCode.valid" mat-flat-button color="primary">
                CONFIRMA COD
            </button>
            <button (click)="resendCode()" class="register-card-button register-card-button-two" mat-stroked-button color="primary">
                RETRIMITERE COD SMS
            </button>
            <div class="register-card__footer-message">
                Aveți deja cont?
                <button routerLink="/pages/login" class="register-card-button register-card-button-two" mat-stroked-button color="primary">
                    autentificați-vă aici
                </button>
            </div>
        </mat-card-content>
    </div>

    <!-- show message success -->
    <div class="row" *ngIf="stepZone == 4">
        <div class="register-green-text-success">
            <div style="margin-right: 18px; color: green;">
                <mat-icon>check</mat-icon>
            </div>

            <div *ngIf="formType == 1">
                {{successMsgContCreatCuSucces}}
            </div>
            <div *ngIf="formType == 2">
                {{successMsgValidareNecesara}}
            </div>
        </div>

        <mat-divider class="divider"></mat-divider>

        <mat-card-content class="register-card__footer">
            <button (click)="navigateToLogin()" class="register-card-button" mat-stroked-button>
                CONTINUAȚI
            </button>
            <div class="register-card__footer-message">
                Aveți deja cont?
                <button routerLink="/pages/login" class="register-card-button register-card-button-two" mat-stroked-button color="primary">
                    autentificați-vă aici
                </button>
            </div>
            <button class="register-card-button register-card-button-two" mat-stroked-button color="primary" (click)="openDialogSuportOnline('INREGISTRARE_CONT_PAGE')">
                ÎNTREBĂRI FRECVENTE / SUPORT TEHNIC
            </button>
        </mat-card-content>
    </div>
</mat-card>

<div *ngIf="loading" class="spinner-overlay">
    <mat-spinner color="primary"></mat-spinner>
</div>