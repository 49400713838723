<div *ngIf="loaded == 1">
    <div class="reset-pin">

        <div class="reset-pin__header">
            <p class="reset-pin__header-title">RECUPERARE PAROLĂ NOTIFICARE | COD UNIC</p>

            <button type="button" class="close" aria-label="Close" (click)="cancel()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <form [formGroup]="formData" (ngSubmit)="submit()" class="reset-pin__form">

            <!-- Nume -->
            <mat-form-field appearance="fill">
                <mat-label>Nume</mat-label>
                <input type="text" matInput formControlName="nume" name="nume" [errorStateMatcher]="matcher" required="required"
                />
                <mat-hint>Introduceți numele de familie</mat-hint>

                <mat-error *ngIf="nume && (nume.dirty || nume.touched) && nume.hasError('required')">
                    <strong>Câmp obligatoriu</strong>
                </mat-error>
            </mat-form-field>

            <!-- Prenume -->
            <mat-form-field appearance="fill">
                <mat-label>Prenume</mat-label>
                <input type="text" matInput formControlName="prenume" name="prenume" [errorStateMatcher]="matcher"
                    required="required" />
                <mat-hint>Introduceți prenumele</mat-hint>

                <mat-error *ngIf="prenume && (prenume.dirty || prenume.touched) && prenume.hasError('required')">
                    <strong>Câmp obligatoriu</strong>
                </mat-error>
            </mat-form-field>

            <!-- cnp / cui -->
            <mat-form-field appearance="fill">
                <input formControlName="cnp" matInput placeholder="CNP/CUI" [errorStateMatcher]="matcher" required="required" type='number'>

                <!-- <mat-icon matSuffix class="primary-text pointer" (click)="openInfo(content)">
                    info
                </mat-icon> -->

                <mat-error *ngIf="cnp_cuiForm && (cnp_cuiForm.dirty || cnp_cuiForm.touched) && cnp_cuiForm.hasError('required')">
                    <strong>Câmp obligatoriu</strong>
                </mat-error>
                <mat-error *ngIf="cnp_cuiForm && (cnp_cuiForm.dirty || cnp_cuiForm.touched) && cnp_cuiForm.hasError('minlength')">
                    <strong>Minim 4 caractere</strong>
                </mat-error>
                <mat-error *ngIf="cnp_cuiForm && (cnp_cuiForm.dirty || cnp_cuiForm.touched) && cnp_cuiForm.hasError('maxlength')">
                    <strong>Max 13 caractere</strong>
                </mat-error>
            </mat-form-field>

            <!-- f_Nume -->
            <mat-form-field *ngIf="formData.value.cnp !== null && formData.value.cnp.toString().length < 13 && formData.value.cnp.toString().length > 2">
                <mat-label>Numele Companie</mat-label>
                <input type="text" matInput formControlName="f_nume" name="f_nume" [errorStateMatcher]="matcher"
                />
                <mat-hint>Introduceți numele companiei</mat-hint>
            </mat-form-field>

            <!-- telefon -->
            <mat-form-field appearance="fill">
                <button class="country-selector" mat-button matPrefix [matMenuTriggerFor]="menu">
                    <mat-icon>expand_more</mat-icon>
                    {{ currentOption.flag }} {{ currentOption.dial_code }}
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="changeCountry(optionRom)">
                        <span class="flag-wrapper">🇷🇴</span>
                        <span class="label-wrapper">
                            {{ optionRom.name }} {{ optionRom.dial_code }}
                        </span>
                    </button>
                    <mat-divider class="divider"></mat-divider>
                    <button mat-menu-item *ngFor="let option of options" (click)="changeCountry(option)">
                        <span class="flag-wrapper">{{ option.flag }}</span>
                        <span class="label-wrapper">
                            {{ option.name }} {{ option.dial_code }}
                        </span>
                    </button>
                </mat-menu>
                <input type="text" matInput formControlName="telefon" />
                <mat-hint>Introduceți numărul de telefon</mat-hint>
                <mat-error *ngIf="!formData.controls['telefon'].valid && formData.controls['telefon'].touched">
                    Câmp obligatoriu
                </mat-error>
            </mat-form-field>

            <!-- email -->
            <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input formControlName="email" matInput [errorStateMatcher]="matcher" type="email" required="required">
                <mat-hint>Introduceți email-ul</mat-hint>
                <mat-error *ngIf="email && (email.dirty || email.touched) && email.hasError('required')">
                    <strong>Câmp obligatoriu</strong>
                </mat-error>
            </mat-form-field>

            <!-- observatii -->
            <mat-form-field appearance="fill">
                <mat-label>Mențiuni</mat-label>
                <textarea matInput formControlName="observatii" name="observatii"></textarea>
            </mat-form-field>

            <div class="reset-pin__form-checkbox">
                <mat-checkbox formControlName="declar_propria_rasp" color="primary">
                    Declar în deplină cunoștință și pe proprie răspundere, cunoscând dispozițiile art. 326 Cod Penal referitoare la falsul în
                    declarații
                </mat-checkbox>
                <br/>
                <mat-checkbox formControlName="declar_acte" color="primary">
                    Declar că sunt de acord cu transmiterea actelor administrative pe cale electronică
                </mat-checkbox>
                <br/>
                <mat-checkbox formControlName="termeni_conditii" color="primary">
                    Sunt de acord cu
                    <a (click)="openTermsModal('termeni-si-conditii')">
                        Termenii și Condițiile de Confidențialitate ale Platformei online a Primăriei Sectorului 4
                    </a>
                </mat-checkbox>
                <br/>
                <mat-checkbox formControlName="consimtamant" color="primary">
                    <a (click)="openConsimtamantModal()">
                        Consimțământ pentru utilizarea datelor cu caracter personal
                    </a>
                </mat-checkbox>
            </div>

            <!-- file_ci wrapper -->
            <div class="reset-pin__form-upload-file">
                <mat-label>
                    <span>Carte de Identitate</span>
                </mat-label>
                <p>Încărcați cartea dumneavoastră de identitate</p>
                <button class="reset-pin__form-upload-container-buttons-upload" type="button" *ngIf="formData.value.file_ci_source === '' && !uploading.file_ci"
                (click)="fileCiInput.click()">
                    <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                    <span>ÎNCARCĂ</span>
                </button>
                <div class="reset-pin__form-upload-container" *ngIf="formData.value.file_ci_source !== '' && !uploading.file_ci">
                    <span *ngIf="formData.value.file_ci_source !== '' && files.file_ci.length > 0" class="reset-pin__form-upload-container-name">
                        <span>
                            {{ (files.file_ci[0].original_name.length > 20)? (files.file_ci[0].original_name | slice:0:20)+'..':(files.file_ci[0].original_name)
                            }}
                        </span>
                    </span>
                    <div class="reset-pin__form-upload-container-buttons">
                        <button class="reset-pin__form-upload-container-buttons-upload reset-pin__form-upload-container-buttons-upload2" type="button" 
                        (click)="fileCiInput.click()">
                            <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                            <span>REÎNCARCĂ</span>
                        </button>
                        <button class="reset-pin__form-upload-container-buttons-delete" type="button" matTooltip="Șterge fișierul încărcat"
                            (click)="fileCiInput.value = ''; clearInputFile('file_ci')">
                            <img class="btn-img" src="assets/icons/delete.svg" alt="" />
                        </button>
                    </div>
                </div>
                <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.file_ci"></mat-spinner>

                <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileCiInput
                    name="fileCiInput" (change)="upload($event, 'file_ci')" style="display: none">
            </div>

            <!-- selfie_ci wrapper -->
            <div class="reset-pin__form-upload-file">
                <mat-label>
                    <span>Selfie cu CI-ul dvs</span>
                </mat-label>
                <p>Încărcați o poză cu cartea dumneavoastră de identitate apropiată de fața dumneavoastră</p>
                <button class="reset-pin__form-upload-container-buttons-upload" type="button" *ngIf="formData.value.selfie_ci_source === '' && !uploading.selfie_ci"
                (click)="fileSelfieCiInput.click()">
                    <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                    <span>ÎNCARCĂ</span>
                </button>
                <div class="reset-pin__form-upload-container" *ngIf="formData.value.selfie_ci_source !== '' && !uploading.selfie_ci">
                    <span *ngIf="formData.value.selfie_ci_source !== '' && files.selfie_ci.length > 0" class="reset-pin__form-upload-container-name">
                        <span>
                            {{ (files.selfie_ci[0].original_name.length > 20)? (files.selfie_ci[0].original_name | slice:0:20)+'..':(files.selfie_ci[0].original_name)
                            }}
                        </span>
                    </span>
                    <div class="reset-pin__form-upload-container-buttons">
                        <button class="reset-pin__form-upload-container-buttons-upload reset-pin__form-upload-container-buttons-upload2" type="button" 
                        (click)="fileSelfieCiInput.click()">
                            <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                            <span>REÎNCARCĂ</span>
                        </button>
                        <button class="reset-pin__form-upload-container-buttons-delete" type="button" matTooltip="Șterge fișierul încărcat"
                            (click)="fileSelfieCiInput.value = ''; clearInputFile('selfie_ci')">
                            <img class="btn-img" src="assets/icons/delete.svg" alt="" />
                        </button>
                    </div>
                </div>
                <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.selfie_ci"></mat-spinner>

                <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileSelfieCiInput
                    name="fileSelfieCiInput" (change)="upload($event, 'selfie_ci')" style="display: none">
            </div>

            <!-- file_cui wrapper -->
            <div class="reset-pin__form-upload-file" *ngIf="formData.value.cnp !== null && formData.value.cnp.toString().length < 13 && formData.value.cnp.toString().length > 2">
                <mat-label>
                    <span>CERTIFICAT ÎNREGISTRARE SOCIETATE</span>
                </mat-label>
                <p>Încărcați o poză cu certificatul de înregistrare al societății dumneavoastră</p>
                <button class="reset-pin__form-upload-container-buttons-upload" type="button" *ngIf="formData.value.file_cui_source === '' && !uploading.file_cui"
                (click)="fileCuiInput.click()">
                    <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                    <span>ÎNCARCĂ</span>
                </button>
                <div class="reset-pin__form-upload-container" *ngIf="formData.value.file_cui_source !== '' && !uploading.file_cui">
                    <span *ngIf="formData.value.file_cui_source !== '' && files.file_cui.length > 0" class="reset-pin__form-upload-container-name">
                        <span>
                            {{ (files.file_cui[0].original_name.length > 20)? (files.file_cui[0].original_name | slice:0:20)+'..':(files.file_cui[0].original_name)
                            }}
                        </span>
                    </span>
                    <div class="reset-pin__form-upload-container-buttons">
                        <button class="reset-pin__form-upload-container-buttons-upload reset-pin__form-upload-container-buttons-upload2" type="button"
                            (click)="fileCuiInput.click()">
                            <img class="btn-img" src="assets/icons/icon-upload.svg" alt="" />
                            <span>REÎNCARCĂ</span>
                        </button>
                        <button class="reset-pin__form-upload-container-buttons-delete" type="button" matTooltip="Șterge fișierul încărcat"
                            (click)="fileCuiInput.value = ''; clearInputFile('file_cui')">
                            <img class="btn-img" src="assets/icons/delete.svg" alt="" />
                        </button>
                    </div>
                </div>
                <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.file_cui"></mat-spinner>

                <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileCuiInput
                    name="fileCuiInput" (change)="upload($event, 'file_cui')" style="display: none">
            </div>

            <div class="reset-pin__form-info">
                <p class="info"><mat-icon class="info-icon">info</mat-icon>* Câmpuri obligatorii</p>
                <p class="info"><mat-icon class="info-icon">info</mat-icon>Fișierele atașate trebuie să fie de tip imagine sau PDF, de maxim 10MB fișierul</p>
            </div>

            <div class="reset-pin__form-actions">
                <button type="button" class="cancel-btn" (click)="cancel()">Anulați</button>
                <button type="submit" class="send-btn" [disabled]="!formData.valid">Trimite Solicitare</button>
            </div>

        </form>

    </div>
</div>