<app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>

<div class="container-fluid">
    <div class="welcome row">
        <div class="welcome-title col-lg-6 d-flex flex-column justify-content-center align-items-start">
            <span class="welcome-title-text">Bine ați venit!</span>
            <span class="welcome-title-subtitle">De aici veți putea accesa toate serviciile publice digitale oferite cetățenilor
                Sectorului 4.</span>
            <div *ngIf="!isAuthenticated()"
                class="section1-content d-flex flex-lg-row flex-column justify-content-lg-between justify-content-center align-items-center no-bg">
                <button class="mt-lg-0 mt-3 d-flex justify-content-center align-items-center" type="button"
                    routerLink="/pages/login">AUTENTIFICAȚI-VĂ AICI!</button>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="welcome-side-box">
                <div class="welcome-small-square">
                    <img src="assets/images/hp-small-square-ps4.jpg" class="img-responsive img-fluid" />
                </div>
                <div class="welcome-image">
                    <video class="elementor-background-video-hosted elementor-html5-video" autoplay [muted]="'muted'" playsinline loop src="https://www.regenerareurbana4.ro/wp-content/uploads/2023/05/342994181_680519600499896_3395361040270132430_n.mp4">
                    </video>
                    <!-- style="width: 3345px; height: 1881.56px;" -->
                </div>
            </div>
        </div>
    </div>
    <div class="section1">
        <div class="section1-content d-flex flex-lg-row flex-column justify-content-lg-between justify-content-center align-items-center">
            <span>Programare pentru Asistență - Biroul de Asistență</span>
            <button class="mt-lg-0 mt-3 d-flex justify-content-center align-items-center" type="button"
                (click)="navigateToNewTab('https://s4programare.netlify.app')">PROGRAMAȚI-VĂ!</button>
        </div>
    </div>

    <div class="section2 container-fluid">
        <div class="section2-content d-flex gap-3 flex-column flex-xl-row">
            <div class="row">
                <div class="section2-content-card d-flex align-items-start col-lg-3 me-1 mb-2">
                    <div class="d-flex flex-column justify-content-between h-100">
                        <span>Depune o cerere pentru</span>
                        <img src="assets/icons/arrow-big.svg" />
                    </div>
                    </div>
            </div>
            
            <div class="d-flex flex-wrap w-100">
                <div (click)="navigateTo(loggedLinks.solicitariUrbanismPlatform)"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/Stema-Bucuresti_line.svg" style="height: 80px;" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                        </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Direcția Generală de Urbanism și Amenajarea Teritoriului Sector 4</span>
                        <!-- <span class="tag">#TaxeȘiImpozite</span> -->
                    </div>
                    </div>
                    <div (click)="navigateTo(publicLinks.solicitariDgitl)"
                        class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                        <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                            <img src="assets/icons/dgitl4.svg" />
                            <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                        </div>
                        <div
                            class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                            <span class="title">Direcția Generală de Impozite și Taxe Locale</span>
                        <!-- <span class="tag">#TaxeȘiImpozite</span> -->
                        </div>
                        </div>
                <div (click)="navigateToNewTab('https://www.mobilitateurbana4.ro/registratura-online/')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/mobilitate-urbana.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Direcția Mobilitate Urbană</span>
                        <!-- <span class="tag">#MobilitateUrbană</span> -->
                        </div>
                        </div>
                <div (click)="navigateTo(publicLinks.solicitariAutorizariComerciale)"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/Stema-Bucuresti_line.svg" style="height: 80px;" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                        </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Serviciul Autorizări Comerciale</span>
                        <!-- <span class="tag">#AutorizăriComerciale</span> -->
                        </div>
                        </div>
                        
                        <div (click)="navigateTo(publicLinks.programariOnline)"
                            class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                            <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                                <img src="assets/icons/dgep.svg" />
                                <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                            </div>
                            <div
                                class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                                <span class="title">Direcția Generală de Evidență a Persoanelor</span>
                                <!-- <span class="tag">#AutorizăriComerciale</span> -->
                            </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="section2 container-fluid">
        <div class="section2-content row" style="background: transparent;">
            <div (click)="navigateTo('/noutati')" class="section3-content-card d-flex flex-column justify-content-between align-items-start col-lg-3 me-1 mb-2">
                <div class="d-flex align-items-center">
                    <span>Ultimele noutăți</span>
                    <img src="assets/icons/homepage-right-arrow.svg" />
                </div>
                <button class="d-flex justify-content-center align-items-center align-self-stretch" type="button">VEZI TOATE
                </button>
            </div>

            <ng-container *ngIf="!isLoading">
                <ng-container *ngFor="let row of articleList">
                    <div class="section3-content-cards d-flex flex-column justify-content-end align-items-start col-lg-3 ms-1 me-1 mb-2" (click)="navigateToArticle(row.id)">
                        <div class="image" [ngStyle]="{'background-image': 'url(' + row.image_url + ')'}"></div>
                        <div class="section2-content-cards-text d-flex flex-column justify-content-start align-items-start">
                            <span class="title">{{ (row.title.length > 33) ? (row.title | slice: 0:32) + '...' : (row.title) }}</span>
                            <span class="tag">{{ formatDate(row.last_updated) }}</span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="isLoading">
                <div class="spinner d-flex flex-column justify-content-center align-items-center col-lg-3">
                    <mat-spinner></mat-spinner>
                    <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
                </div>
            </ng-container>
        </div>
    </div> -->

    <div class="section2 container-fluid">
        <div class="section2-content d-flex gap-3 flex-column flex-xl-row"
            style="background: linear-gradient(0deg, #FFD180 0%, #FFD180 100%), #FE7800;">
            <div class="row">
                <div class="section2-content-card d-flex align-items-start me-1 mb-2">
                    <div class="d-flex flex-column justify-content-between h-100">
                        <span>Plăți Online</span>
                        <img src="assets/icons/arrow-big.svg" />
                    </div>
                </div>
            </div>
        
            <div class="d-flex flex-wrap w-100">
                <div class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2"
                    (click)="navigateTo(loggedLinks.platiOnline)">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/taxe-nedebitate.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div
                        class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Taxe și Impozite</span>
                        <!-- <span class="tag">#ImpoziteȘiTaxe</span> -->
                    </div>
                </div>
    <div (click)="navigateTo(publicLinks.programariOnline+'/plati-online')"
        class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
        <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
            <img src="assets/icons/evidenta-populatiei.svg" />
            <img class="arrow-right" src="assets/icons/arrow-right.svg" />
        </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Evidența Populației</span>
                        <!-- <span class="tag">#EvidențaPopulației</span> -->
                        </div>
                        </div>
                <div (click)="navigateToNewTab('https://www.mobilitateurbana4.ro/plati/')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/parcari.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Parcări</span>
                        <!-- <span class="tag">#MobilitateUrbană</span> -->
                        </div>
                        </div>
                <div class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2"
                    (click)="navigateTo(loggedLinks.platiAlteTaxeAmenzi)">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/taxe-nedebitate.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Alte Taxe și Amenzi</span>
                        <!-- <span class="tag">#ImpoziteȘiTaxe</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="section2 container-fluid">
        <div class="section2-content d-flex gap-3 flex-column flex-xl-row"
            style="background: linear-gradient(0deg, rgba(105, 240, 174, 0.16) 0%, rgba(105, 240, 174, 0.16) 100%), rgba(105, 240, 174, 0.70);">
            <div class="row">
                <div class="section2-content-card d-flex align-items-start me-1 mb-2">
                    <div class="d-flex flex-column justify-content-between h-100">
                        <span>Link-uri rapide</span>
                        <img src="assets/icons/arrow-big.svg" />
                        </div>
                </div>
            </div>
    
            <div class="d-flex flex-wrap w-100">
                <div (click)="navigateToNewTab('https://ps4.ro/monitorul-oficial-local/hotararile-autoritatii-deliberative/hotarari/')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/hotarari-consiliu.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Hotărâri consiliu</span>
                        <!-- <span class="tag">#ConsiliulLocal4</span> -->
                        </div>
                        </div>
                <div (click)="navigateToNewTab('https://ps4.ro/monitorul-oficial-local/dispozitiile-autoritatii-executive/dispozitii/')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dispozitii.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Dispoziții</span>
                        <!-- <span class="tag">#PrimăriaSectorului4</span> -->
                        </div>
                        </div>
                <div (click)="navigateToNewTab('https://taxeimpozite4.ro')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dgitl4.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                        </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Taxe și Impozite</span>
                        <!-- <span class="tag">#TaxeȘiImpozite</span> -->
                        </div>
                        </div>
                <div (click)="navigateToNewTab('https://mobilitateurbana.ps4.ro')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/parcari.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Parcări</span>
                        <!-- <span class="tag">#MobilitateUrbană</span> -->
                        </div>
                        </div>
                <div (click)="navigateToNewTab('https://dgaspc.ps4.ro')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dgaspc.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                    </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Asistență Socială și Protecția Copilului</span>
                        <!-- <span class="tag">#DGASPCS4</span> -->
                        </div>
                        </div>
                <div (click)="navigateToNewTab('https://deps4.ro')"
                    class="section2-content-cards d-flex flex-column justify-content-between ms-1 me-1 mb-2">
                    <div class="section2-content-cards-header d-flex flex-row justify-content-between align-items-start">
                        <img src="assets/icons/dgep.svg" />
                        <img class="arrow-right" src="assets/icons/arrow-right.svg" />
                        </div>
                    <div class="section2-content-cards-text d-flex flex-column justify-content-end align-items-start align-self-stretch">
                        <span class="title">Evidența Persoanelor</span>
                        <!-- <span class="tag">#EvidențaPersoanelor</span> -->
                        </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>